.-home{
	/*First*/
	&-first{
		padding-top: 109px;
		display: flex;
		min-height: 100vh;
		background-position-x: center;
		background-position-y: top;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		/*background-color: #26294d;*/
		background-color: #131428;
		/*background-attachment: fixed;*/


		@include media-breakpoint-down(md) {
			/*min-height: auto;
			background-position: center 40%;
			background-size: 140%;*/
			background-position: center;
			padding-top: 60px;
		}

		&-wrap{
			display: flex;
			flex-direction: row-reverse;
			align-items: center;

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}
		}
		&-image,
		&-content{
			/*@include make-col(6);*/

			/*@include media-breakpoint-down(md) {
				width: 100%;
			}*/
		}

		&-image{
			figure{ margin: 0; }

			@include media-breakpoint-down(md) {
				img{
					max-width: 75%;
					margin: 0 auto;
				}
			}
		}

		&-content{
			@include make-col(7);
			padding-right: 10%;
			margin-top: 10vh;
			font-size: 18px;
			@include font-rem(18);
			font-weight: $regular;
			margin-right: auto;

			@include media-breakpoint-down(xxl) {
				margin-top: 2vh;
			}

			@include media-breakpoint-down(md) {
				padding-right: 0;
				width: 70%;
			}

			h1, h2, h3{
				color: $white;
				font-weight: $bold;
				font-size: 40px;
				@include font-rem(40);
				line-height: 1;
				margin-bottom: 28px;

				@include media-breakpoint-down(xxl) {
					font-size: 34px;
					@include font-rem(34);
				}

				@include media-breakpoint-down(md) {
					font-size: 30px;
					@include font-rem(30);
					line-height: 1.2;
					margin-bottom: 15px
				}

				@include media-breakpoint-down(sm) {
					font-size: 18px;
					@include font-rem(18);
					/*line-height: 1.2;
					margin-bottom: 15px*/
				}
			}

			p{
				color: $white;
				font-size: 18px;
				@include font-rem(18);
				line-height: 1.66;
				font-weight: $regular;

				@include media-breakpoint-down(xxl) {
					font-size: 16px;
					@include font-rem(16);
				}

				@include media-breakpoint-down(md) {
					font-size: 16px;
					@include font-rem(16);
					line-height: 1.5;
				}
				@include media-breakpoint-down(sm) {
					font-size: 14px;
					@include font-rem(14);
				}
			}

			.-btn-primary{ 
				margin-top: 20px;
				padding: 15px 50px;

				@include media-breakpoint-down(md) {
					display: block;
					margin: 30px 12% 0;
					text-align: center;
				}
			}
		}
	}

	/*Second*/
	&-second{
		background-position-x: center;
		background-position-y: top;
		background-repeat: no-repeat;
		background-color: #f1715c;

		@include media-breakpoint-down(md) {
			background-size: 0;
			padding-top: 15vh;
		}
		@include media-breakpoint-down(sm) {
			background-size: 0;
			padding-top: 20px;
		}

		&-wrap{
			display: flex;
			flex-direction: row;
			padding-top: 80px;
			padding-bottom: 95px;

			@include media-breakpoint-down(lg) {
				flex-direction: column;
			}
			@include media-breakpoint-down(sm) {
				padding-top: 60px;
				padding-bottom: 60px;
			}
		}

		&-image,
		&-form,
		&-content{
			@include make-col(8);
			margin: 0 auto;

			@include media-breakpoint-down(lg) {
				width: 100%;
			}

		}

		&-image,
		&-form{
			display: flex;
			align-items: center;

			h2{
				color: #fff;
				text-align: center;
				@include media-breakpoint-down(sm) {
					font-size: 24px;
					@include font-rem(24);
				}
			}
		}

		&-form{
			flex-direction: column;
			align-items: flex-start;
		}

		&-content{
			padding-left: 70px;

			@include media-breakpoint-down(lg) {
				padding-left: 0;
			}

			&.nopd{ padding-left: 0;}

			h2, h3{
				font-size: 33px;
				@include font-rem(33);
				line-height: 1.1;
				margin-bottom: 11px;
				font-weight: $bold;
				
			}

			h4{
				color: $blue-medium;
				font-size: 16px;
				font-weight: $bold;
				margin-bottom: 11px;        
			}

			p{
				color: $black;
				font-size: 14px;
				@include font-rem(14);
				font-weight: $regular;
				line-height: 2.14;
				margin-bottom: 20px;
				
				&+h2,
				&+h3{
					margin-top: 40px;
				}

				strong{ color: $green-base; }
			}

			.-btn-primary{
				@include media-breakpoint-down(md) {
					display: block;
					margin: 30px 12% 0;
					text-align: center;
				}
			}
		}
	}

	/*Third*/
	&-third{
		padding-top: 106px;
		padding-bottom: 64px;
		background-color: rgba(107, 221, 156, .3);

		@include media-breakpoint-down(md) {
			background-size: 0;
			padding-top: 15vh;
		}

		&-wrap{
			display: flex;
			flex-direction: row;

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}
		}

		&-image,
		&-content{
			@include make-col(6);
			position: relative;
			z-index: 1;

			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}

		&-image{
			img{ 
				margin-left: auto;
				@include media-breakpoint-down(md) {
					margin-top: 40px; 
					margin-right: auto;
				}        
			}
		}

		&-content{
			position: relative;
			z-index: 1;

	      	/*@include media-breakpoint-down(md) {
	        position: relative;
	        z-index: 1;
	        }*/

	        h2, h3{
	        	font-size: 33px;
	        	@include font-rem(33);
	        	line-height: 1.1;
	        	margin-bottom: 11px;
	        	font-weight: $bold;
	        }

	        h4{
	        	color: $blue-medium;
	        	font-size: 18px;
	        	@include font-rem(18);
	        	font-weight: $bold;
	        	margin-bottom: 11px;        
	        }

	        p{
	        	font-size: 14px;
	        	@include font-rem(14);
	        	font-weight: $regular;
	        	color: $blue-medium;

	        	strong{ 
	        		font-weight: $extrabold;

	        		a{
	        			color: $blue-medium;
	        			text-decoration: none;
	        			font-weight: inherit;
	        			&:hover,
	        			&:focus,
	        			&:active{
	        				text-decoration: underline;
	        			}         
	        		}
	        	}

	        	&+h2, &+h3, &+h4{ margin-top: 40px;}
	        }

	        .-btn-white{ 
	        	margin-top: 40px;
	        	display: block;
	        	max-width: 515px;

	        	@include media-breakpoint-down(md) {
	        		padding: 17px;
	        		br{ display: none; }
	        	}
	        }
	    }


    }
	/*Fourth*/
	&-fourth{
		/*padding-top: 98px;
		padding-bottom: 103px;*/
		padding-top: 60px;
		padding-bottom: 60px;
		align-items: center;
		background-position-x: 90%;
		background-position-y: top;
		background-repeat: no-repeat;
		position: relative;

		@include media-breakpoint-down(md) {
			padding-top: 15vh;
			padding-bottom: 5vh;
		}

		&:before{
			content: '';
			position: absolute;
			top: -30%;
			right: 0;
			width: 100%;
			height: 130%;
			/*background-image: url('../images/big-leaf.png');*/
			background-position-x: 83%;
			background-position-y: top;
			background-repeat: no-repeat;

			@include media-breakpoint-down(lg) {
				top: -100px;
				left: 0;
				width: 100%;
				height: calc(100% + 100px);        
			}
		}

		.container{ position: relative;}

		&-wrap{ 
			overflow: hidden;

			p{line-height: 1.8;}
		}

		&-content{
			h2, h3{
				font-size: 33px;
				@include font-rem(33);
				line-height: 1.1;
				margin-bottom: 11px;
				font-weight: $bold;
			}

			h4{
				color: $blue-medium;
				font-size: 22px;
				@include font-rem(22);
				font-weight: $bold;
				margin-bottom: 11px;
			}
		}

		&-rpt{
			display: flex;
			flex-wrap: wrap;
			margin-top: 25px;

			@include media-breakpoint-down(lg) {
				margin-top: 60px;
			}

			@include media-breakpoint-down(md) {
				margin-top: 30px;
			}

			&-item{
				flex: 0 0 auto;
				width: calc(33.33333% - 20px);
				padding: 0 10px;
				margin-bottom: 48px;

				@include media-breakpoint-down(lg) {
					width: calc(50% - 20px);
					margin-bottom: 30px;
				}

				@include media-breakpoint-down(md) {
					width: 100%;
					padding: 0;
				}

				figure{ 
					margin-bottom: 17px;
					min-height: 91px;
					display: flex;
					align-items: center;
				}
				.figure{
					margin-bottom: 17px;
					min-height: 91px;
					display: flex;
					align-items: center;
					.iconify{
						font-size: 60px;
						color: $orange-base;
					}
				}

				h3{
					color: $blue-medium;
					font-size: 20px;
					@include font-rem(20);
					font-weight: $bold;
				}

				p{
					color: $black;
					font-size: 14px;
					@include font-rem(14);
				}
			}

		}

	}        
}


/*Helpers*/
.pl70{ 
	padding-left: 70px;
	@include media-breakpoint-down(lg) {
		padding-left: 0;
	}
}

/* alpha form */
.-alpha-form-wrap{
	@include make-col(12);
	margin-top: 20px;
	form{
		display: flex;
		flex-direction: column;
		border-radius: 22px;
		background-color: $white;
		padding: 20px;
		box-shadow: 2px 2px 10px rgba(0,0,0,0.16);

		@include media-breakpoint-down(md) {
			padding: 12px 16px;
			box-shadow: 1px 3px 10px rgba(0,0,0,0.36);
		}

		fieldset{ 
			max-width: 100%;
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;


			&.columns-2{
				flex-wrap: wrap;
				@include media-breakpoint-only(lg) {
					margin-bottom: 0;
				}
				@include media-breakpoint-down(md) {
					margin-bottom: 0;
				}
				input{
					flex: 0 0 auto;
					width: calc(50% - 4px);
				}
				.wpcf7-form-control-wrap{
					flex: 0 0 auto;
					width: calc(50% - 4px);
					/*@include media-breakpoint-only(xl) {
						background-color: blue;
					}*/
					@include media-breakpoint-only(lg) {
						width: 100%;
						margin-bottom: 10px;
					}

					@include media-breakpoint-down(md) {
						width: 100%;
						margin-bottom: 10px;
					}

					&>input{
						flex: 0 0 auto;
						width: 100%;
					}
				}
			}
		}

		.inputs-list{
			list-style: none;
			margin: 20px 0 0;
			padding: 0;
		}

		input{
			border: 1px solid $orange-base;
			color: $blue-base;
			font-size: 14px;
			@include font-rem(14);
			padding: 6px 12px;
			border-radius: 54px;
			height: 44px;
			margin-bottom: 6px;

			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}

		input+input{ margin-left: 0;}

		textarea{
			resize: none;
			border-radius: 22px;
			border: 1px solid $green-base;
			padding: 19px 16px;
			height: 121px;
			width: 100%;
		}

		button[type="submit"], input[type="submit"]{
			margin-top: 10px;
			margin-bottom: 10px;
			border: 1px solid $blue-base;
			padding: 0;
			&:hover,
			&:focus,
			&:active{
				color: $white;
				background-color: $orange-base;
				border-color: $orange-base;
			}
		}


		input[type="checkbox"]{
			padding: 0;
			height: auto;
			margin-right: 10px;
			width: auto;

			@include media-breakpoint-down(md) {
				width: auto;
			}
		}

	}
}
