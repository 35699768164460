@charset "UTF-8";
/*$light:		300;*/
/*@import 'helpers/responsive';*/
/* property name | duration | timing function | delay */
/* Usage @include border-radius(5px); */
/* Usage: @include box-shadow(0,0,20,0,rgba(0,0,0,0.1)); */
/* Usage @include scale(1); */
/* Usage @include rotate(135deg); */
/* Placeholders */
.horizontal-list-clean-style, .-footer-socials, .-footer-navbar--nav,
.-footer-navbar ul[role="menu"], .-socials ul, .-about-second-sl-item .text ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.-footer-navbar--nav,
.-footer-navbar ul[role="menu"] {
  display: flex;
  flex-direction: column; }

.horizontal-list-clean-style, .-flex-row, .-footer-socials, .-socials ul {
  display: flex;
  flex-direction: row; }

.header-block {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center; }

.img-responsive, .brand {
  display: block;
  height: auto;
  max-width: 100%; }

.dropdown-menu, .cookiebar-left-tooltip {
  box-shadow: 0 0 87px rgba(0, 0, 0, 0.16); }

/*@import 'helpers/wp';*/
/*!
 * Bootstrap Reboot v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #FFFEFF;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1 {
      font-size: 2.5rem; } }

h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2 {
      font-size: 2rem; } }

h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3 {
      font-size: 1.75rem; } }

h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4 {
      font-size: 1.5rem; } }

h5 {
  font-size: 1.25rem; }

h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 0.875em; }

mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: underline; }
  a:hover {
    color: #0a58ca; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #FFFEFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: dotted 1px;
  outline: -webkit-focus-ring-color auto 5px; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

/*!
 * Bootstrap Grid v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2); }
  .row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; } }

@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; } }

@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; } }

@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; } }

@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*@import 'base/bootstrap/forms';
@import 'base/bootstrap/bootstrap-utilities';*/
body {
  background-color: #FFFEF8;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  color: #000000;
  position: relative; }

main {
  overflow-y: hidden; }

.hide {
  display: none; }

.fixed {
  position: fixed; }

.txtcenter {
  text-align: center; }

.-flex-row {
  align-items: center; }

.-hfull {
  min-height: 100vh; }

.-space-between {
  justify-content: space-between; }

blockquote {
  max-width: 70%;
  font-size: 18px;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #00101e;
  display: flex;
  justify-content: center;
  margin: 40px auto 13px;
  text-align: left; }
  blockquote p {
    border-left: 4px solid #6BDD9C;
    padding-left: 47px;
    line-height: inherit;
    margin-bottom: 0; }
  blockquote + p,
  blockquote + h3,
  blockquote + h4,
  blockquote + h5,
  blockquote + h6 {
    margin-top: 56px; }

.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+, Safari 6+, Safari 6+ iOS */
  -webkit-transition: all linear .25s;
  -moz-transition: all linear .25s;
  -ms-transition: all linear .25s;
  -o-transition: all linear .25s;
  transition: all linear .25s; }
  .grayscale:hover {
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(0%); }
  .grayscale:hover {
    /* @include grayscale-on-mouseover();*/ }

.-title {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #1E2D61; }
  @media (max-width: 767.98px) {
    .-title {
      font-size: 24px;
      font-size: 1.5rem; } }

.-subtitle {
  /* font-size: 18px;
  @include font-rem(18);
  font-weight: $regular;

      @include media-breakpoint-down(sm) {
        font-size: 15px;
        @include font-rem(15);
      }*/ }

h1, h2, h3, h4, h5, h6 {
  /*position: relative;
  color: $blue-base;*/
  margin: 0 0 1.4rem; }

h1 {
  color: #1E2D61;
  font-weight: 700;
  font-size: 44px;
  font-size: 2.75rem;
  line-height: 1.15;
  /*
  padding-bottom: 8px;
  @include media-breakpoint-down(sm) {
    font-size: 26px;
    @include font-rem(26);
  }*/ }

h2 {
  color: #1E2D61;
  font-weight: 700;
  font-size: 38px;
  font-size: 2.375rem;
  /*line-height: 1.54;*/
  line-height: 1.2;
  margin-bottom: 15px; }

h3 {
  color: #1E2D61;
  font-weight: 700;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.5; }

h5 {
  font-weight: normal;
  text-decoration: none;
  font-family: Montserrat,sans-serif;
  font-style: normal;
  font-size: 16px;
  text-transform: none;
  margin: 0 0 1.4rem; }

.lv-header {
  padding-top: 15px;
  padding-bottom: 15px;
  /*background-color: $white;*/
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2; }
  @media (min-width: 768px) {
    .lv-header {
      padding-bottom: 0; } }
  .lv-header-gn {
    background-color: #6BDD9C; }
    @media (min-width: 768px) {
      .lv-header-gn {
        padding-bottom: 20px; } }
  @media (min-width: 992px) {
    .lv-header-wt {
      background-color: #fff; } }
  .lv-header-pages {
    padding-top: 15px;
    padding-bottom: 15px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2; }
  .lv-header-inner {
    position: relative;
    display: flex;
    flex-wrap: wrap; }
  .lv-header-brand {
    transition: all 0.8s ease-in;
    display: flex;
    align-items: center;
    width: auto;
    padding: 0;
    max-width: 190px;
    margin-right: auto;
    margin-left: auto; }
    @media (prefers-reduced-motion: reduce) {
      .lv-header-brand {
        transition: none; } }
    @media (max-width: 767.98px) {
      .lv-header-brand {
        margin-left: auto;
        z-index: 1000; } }
    .lv-header-brand a {
      transition: all 0.25s ease-in;
      position: relative; }
      @media (prefers-reduced-motion: reduce) {
        .lv-header-brand a {
          transition: none; } }
      .lv-header-brand a:active, .lv-header-brand a:focus, .lv-header-brand a:hover {
        transform: scale(1.03);
        opacity: .9; }

.-header-inner {
  position: relative;
  display: flex;
  flex-wrap: wrap; }

.-header-brand {
  transition: all 0.8s ease-in;
  display: flex;
  align-items: center;
  width: auto;
  padding: 0;
  max-width: 190px;
  margin: 0 auto 15px; }
  @media (prefers-reduced-motion: reduce) {
    .-header-brand {
      transition: none; } }
  @media (min-width: 768px) {
    .-header-brand {
      flex: 0 0 auto;
      width: 100%;
      /*width: 100%; */
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      z-index: 1000;
      margin-left: 0; } }
  .-header-brand a {
    transition: all 0.2s ease-in;
    position: relative; }
    @media (prefers-reduced-motion: reduce) {
      .-header-brand a {
        transition: none; } }
    .-header-brand a:active, .-header-brand a:focus, .-header-brand a:hover {
      transform: scale(1.03);
      opacity: .9; }

.menu-trigger,
.search-trigger {
  position: absolute;
  width: 24px;
  height: 24px;
  color: #1E2D61;
  font-size: 24px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .menu-trigger-desktop,
  .search-trigger-desktop {
    top: 10px; }
  .menu-trigger-mobile,
  .search-trigger-mobile {
    top: 28px; }
  .menu-trigger .-btn,
  .search-trigger .-btn {
    transition: all 0.2s ease-in;
    position: relative;
    outline: none;
    display: inline-block;
    border: none;
    text-decoration: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1; }
    @media (prefers-reduced-motion: reduce) {
      .menu-trigger .-btn,
      .search-trigger .-btn {
        transition: none; } }

.menu-trigger-desktop {
  right: 0px;
  z-index: 2000; }
  @media (min-width: 992px) {
    .menu-trigger-desktop {
      display: none; } }
  @media (max-width: 767.98px) {
    .menu-trigger-desktop {
      visibility: hidden; } }

.menu-trigger-mobile {
  right: 17px; }
  @media (min-width: 768px) {
    .menu-trigger-mobile {
      visibility: hidden; } }

.menu-trigger-desktop .iconify, .menu-trigger-mobile .iconify {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

.menu-trigger.-trigg-white {
  color: #FFFEFF; }

@media (min-width: 768px) {
  .search-trigger-desktop {
    visibility: hidden; } }

.search-trigger-mobile {
  left: 17px; }
  @media (min-width: 768px) {
    .search-trigger-mobile {
      visibility: hidden; } }

@media (max-width: 991.98px) {
  .brand {
    max-width: 115px; } }

@media (min-width: 992px) {
  .brand-mob {
    display: none; } }

@media (max-width: 991.98px) {
  .brand-desk {
    display: none; } }

.dropdown {
  position: relative;
  padding-right: 0; }
  .dropdown .iconify {
    font-size: 24px; }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    min-width: 100%;
    display: none;
    padding: 0;
    margin: 0;
    font-size: 1em;
    /*color: #212529;*/
    text-align: left;
    list-style: none;
    background-color: #1E2D61;
    color: #FFFEFF;
    background-clip: padding-box;
    border-radius: .25rem; }
    .dropdown-menu:before {
      content: "";
      position: absolute;
      top: -6px;
      right: 6px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      /*border-bottom: 6px solid $white;*/ }
    .dropdown-menu > li {
      padding: 0; }
      .dropdown-menu > li a {
        text-decoration: none;
        width: 100%;
        justify-content: flex-start;
        line-height: 2.6rem;
        white-space: nowrap;
        color: inherit; }
      .dropdown-menu > li:first-child {
        border-top: none; }
    .dropdown-menu > .active > a,
    .dropdown-menu > .active > a:focus,
    .dropdown-menu > .active > a:hover {
      /*color: #fff;
			background: #8dc63f;*/ }
  .dropdown:hover .dropdown-menu, .dropdown.open .dropdown-menu {
    display: block; }

.lv-footer {
  position: relative;
  background-color: #00101e;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  color: #FFFEFF; }
  .lv-footer-alt {
    background-color: #6BDD9C;
    padding-top: 27px;
    padding-bottom: 36px; }
  .lv-footer p {
    margin-bottom: 0; }
    @media (max-width: 575.98px) {
      .lv-footer p {
        text-align: center;
        margin-bottom: 1rem; } }

.-footer {
  /*&-signature{
		@include media-breakpoint-down(md) {
			font-size: 12px;
			@include font-rem(12);
			margin: 0 auto;
			color: $purple-base;
		}
	}*/ }
  .-footer-title {
    color: #FFFEFF;
    margin-bottom: 27px;
    font-size: 16px;
    font-size: 1rem;
    /*font-weight: $semibold;*/ }
  .-footer-inner {
    /*padding-bottom: 20px;*/
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .-footer-inner-alt {
      padding-bottom: 0; }
    .-footer-inner > * {
      flex: 1;
      width: auto; }
  .-footer-about {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 24px; }
    @media (min-width: 768px) {
      .-footer-about {
        max-width: 400px;
        margin-bottom: 0; } }
    @media (max-width: 767.98px) {
      .-footer-about {
        display: flex;
        flex-wrap: wrap;
        align-items: center; } }
    @media (max-width: 575.98px) {
      .-footer-about {
        padding-top: 140px;
        flex-direction: column-reverse; } }
  .-footer-description {
    margin-bottom: 0;
    max-width: 50%; }
    @media (max-width: 767.98px) {
      .-footer-description {
        flex: 0 0 auto;
        width: 50%;
        margin-bottom: 112px; } }
    @media (max-width: 575.98px) {
      .-footer-description {
        margin: auto;
        width: 100%;
        font-size: 14px;
        font-size: 0.875rem; } }
    .-footer-description p {
      margin-bottom: 14px;
      line-height: 1.7; }
      @media (max-width: 767.98px) {
        .-footer-description p {
          margin-bottom: 0; } }
  .-footer-socials {
    color: #FFFEFF;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-size: 17px;
    font-size: 1.0625rem; }
    .-footer-socials li {
      margin-bottom: 10px;
      /*margin-right: 14px;*/
      margin-right: 5px; }
      .-footer-socials li:first-child {
        margin-left: 0; }
    .-footer-socials a {
      transition: all 0.2s ease-in-out;
      width: 34px;
      height: 34px;
      color: #FFFEFF;
      border: 2px solid #FFFEFF;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (prefers-reduced-motion: reduce) {
        .-footer-socials a {
          transition: none; } }
      .-footer-socials a:hover {
        background-color: #FFFEFF;
        color: #00101e; }
  .-footer-brand {
    display: flex;
    align-items: center;
    width: auto;
    max-width: 180px;
    margin-bottom: 28px; }
    @media (max-width: 767.98px) {
      .-footer-brand {
        flex: 0 0 auto;
        width: 50%;
        margin-bottom: 112px; } }
    @media (max-width: 575.98px) {
      .-footer-brand {
        margin: auto;
        width: 100%;
        margin-left: 0; } }
    @media (max-width: 767.98px) {
      .-footer-brand.soon {
        margin-bottom: 152px; } }
    @media (max-width: 575.98px) {
      .-footer-brand.soon {
        margin-bottom: 0;
        margin-left: 0; } }
    .-footer-brand-alt {
      margin-bottom: 0; }
    .-footer-brand a {
      transition: all 0.2s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .-footer-brand a {
          transition: none; } }
      .-footer-brand a:active, .-footer-brand a:focus, .-footer-brand a:hover {
        transform: scale(1.03);
        opacity: .9; }
  .-footer-navs {
    display: flex;
    flex: 1;
    width: auto; }
  .-footer-navbar {
    flex: 1;
    /*&+&{margin-left: 85px;}*/ }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .-footer-navbar {
        flex: 0 0 auto;
        width: 33.33333%; } }
    @media (max-width: 575.98px) {
      .-footer-navbar {
        flex: 0 0 auto;
        width: 100%; } }
    .-footer-navbar--nav,
    .-footer-navbar ul[role="menu"] {
      color: #FFFEFF;
      padding: 0;
      margin: 0; }
      .-footer-navbar--nav li,
      .-footer-navbar ul[role="menu"] li {
        list-style: none;
        padding: 0;
        font-size: 16px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        margin-bottom: 15px; }
        .-footer-navbar--nav li a,
        .-footer-navbar ul[role="menu"] li a {
          color: inherit;
          text-decoration: none;
          position: relative;
          transition: all 0.2s ease-in-out; }
          @media (prefers-reduced-motion: reduce) {
            .-footer-navbar--nav li a,
            .-footer-navbar ul[role="menu"] li a {
              transition: none; } }
          .-footer-navbar--nav li a .iconify,
          .-footer-navbar ul[role="menu"] li a .iconify {
            margin-right: 16px; }
        .-footer-navbar--nav li.current-menu-item, .-footer-navbar--nav li:hover,
        .-footer-navbar ul[role="menu"] li.current-menu-item,
        .-footer-navbar ul[role="menu"] li:hover {
          opacity: .8;
          text-decoration: underline; }
        .-footer-navbar--nav li.active,
        .-footer-navbar ul[role="menu"] li.active {
          /*color: $gray-base;*/ }
  .-footer-download, .-footer-download-alt {
    flex: 0 0 auto;
    /*margin-top: 30px;*/ }
    @media (max-width: 767.98px) {
      .-footer-download, .-footer-download-alt {
        position: absolute;
        display: flex;
        justify-content: space-around;
        right: 0;
        width: 100%;
        top: 96px;
        margin-top: 0;
        flex-wrap: wrap; } }
    @media (max-width: 575.98px) {
      .-footer-download, .-footer-download-alt {
        top: 0; }
        .-footer-download .-footer-title, .-footer-download-alt .-footer-title {
          width: 100%;
          text-align: center; } }
    .-footer-download a, .-footer-download-alt a {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      justify-content: center; }
      @media (max-width: 767.98px) {
        .-footer-download a, .-footer-download-alt a {
          padding: 12px 20px;
          display: flex;
          align-items: center; } }
      .-footer-download a .iconify, .-footer-download-alt a .iconify {
        font-size: 28px;
        font-size: 1.75rem;
        margin-right: 8px; }
        @media (max-width: 767.98px) {
          .-footer-download a .iconify, .-footer-download-alt a .iconify {
            font-size: 26px;
            font-size: 1.625rem; } }
    .-footer-download a + a, .-footer-download-alt a + a {
      margin-top: 15px; }
      @media (max-width: 767.98px) {
        .-footer-download a + a, .-footer-download-alt a + a {
          margin-top: 0; } }
  .-footer-out {
    background-color: #00101e;
    color: #FFFEFF;
    padding: 0 0 38px; }
    .-footer-out-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 575.98px) {
        .-footer-out-wrapper {
          justify-content: flex-start; } }
      .-footer-out-wrapper > * {
        flex: 0 0 auto;
        width: auto; }
      .-footer-out-wrapper a {
        text-decoration: none;
        color: inherit; }
  .-footer-copyright {
    font-size: 16px;
    font-size: 1rem;
    color: #FFFEFF;
    text-decoration: none; }
    @media (max-width: 575.98px) {
      .-footer-copyright {
        flex: 0 0 auto;
        width: 100%;
        font-size: 12px;
        font-size: 0.75rem;
        text-align: center; } }

.-btn {
  transition: all 0.2s ease-in-out;
  position: relative;
  outline: none;
  display: inline-block;
  border: none;
  text-decoration: none;
  /* background: transparent; */
  color: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  line-height: 1; }
  @media (prefers-reduced-motion: reduce) {
    .-btn {
      transition: none; } }
  .-btn:hover, .-btn:focus, .-btn:active {
    text-decoration: none;
    outline: none; }
  .-btn-transparent {
    background-color: transparent; }
  .-btn-primary {
    transition: all 0.2s ease-in-out;
    display: inline-block;
    background-color: #010038;
    color: #FFFEFF;
    border: 1px solid #010038;
    font-weight: 500;
    font-size: 12px;
    font-size: 0.75rem;
    border-radius: 30px;
    padding: 17px 32px;
    text-decoration: none; }
    @media (prefers-reduced-motion: reduce) {
      .-btn-primary {
        transition: none; } }
    @media (min-width: 768px) {
      .-btn-primary:hover {
        color: #010038;
        background-color: #FFFEFF; } }
    .-btn-primary:valid {
      color: #FFFEFF; }
  .-btn-secondary {
    transition: all 0.2s ease-in-out;
    background-color: #E35207;
    color: #FFFEFF;
    padding: 17px 34px;
    text-transform: uppercase;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 600;
    text-decoration: none;
    border-radius: 30px;
    margin-top: 10px;
    display: inline-block; }
    @media (prefers-reduced-motion: reduce) {
      .-btn-secondary {
        transition: none; } }
    .-btn-secondary:active, .-btn-secondary:focus, .-btn-secondary:hover {
      background-color: #C84800;
      color: #FFFEFF; }
  .-btn-terciary {
    transition: all 0.2s ease-in-out;
    background-color: transparent;
    border: 2px solid #6BDD9C;
    color: #6BDD9C;
    padding: 17px 34px;
    text-transform: uppercase;
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 500;
    text-decoration: none;
    border-radius: 30px; }
    @media (prefers-reduced-motion: reduce) {
      .-btn-terciary {
        transition: none; } }
    .-btn-terciary:active, .-btn-terciary:focus, .-btn-terciary:hover {
      background-color: #010038;
      border-color: #010038;
      color: #FFFEFF; }
  .-btn-read {
    transition: all 0.2s ease-in-out;
    background-color: transparent;
    color: #010038;
    padding: 0;
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 700;
    text-decoration: none;
    border: none; }
    @media (prefers-reduced-motion: reduce) {
      .-btn-read {
        transition: none; } }
    @media (min-width: 768px) {
      .-btn-read {
        font-size: 16px;
        font-size: 1rem; } }
    .-btn-read .iconify {
      margin-left: 10px;
      font-size: 24px;
      font-size: 1.5rem;
      color: #6BDD9C; }
  .-btn-flex-bottom-start {
    margin-top: auto;
    align-self: flex-start; }
  .-btn-disabled {
    opacity: .5;
    cursor: default; }
  .-btn-white {
    transition: all 0.2s ease-in-out;
    position: relative;
    outline: none;
    display: inline-block;
    border: none;
    text-decoration: none;
    color: #1E2D61;
    background-color: #FFFEFF;
    font-weight: 700;
    cursor: pointer;
    border-radius: 41px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center;
    line-height: 1.38;
    padding: 17px 50px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16); }
    @media (prefers-reduced-motion: reduce) {
      .-btn-white {
        transition: none; } }
    .-btn-white:hover, .-btn-white:focus, .-btn-white:active {
      background-color: #1E2D61;
      color: #FFFEFF;
      text-decoration: none;
      outline: none;
      font-weight: 500; }
    .-btn-white b, .-btn-white strong {
      color: #6BDD9C; }

.scroll-down {
  transition: all 0.2s ease-in-out;
  position: relative;
  outline: none;
  display: inline-block;
  border: none;
  text-decoration: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  line-height: 1;
  outline: none; }
  @media (prefers-reduced-motion: reduce) {
    .scroll-down {
      transition: none; } }
  .scroll-down:hover, .scroll-down:focus, .scroll-down:focus {
    outline: none; }

#customize-controls,
#customize-controls-rel {
  list-style: none;
  padding: 0;
  margin: 0 0 25px;
  display: flex; }
  #customize-controls li,
  #customize-controls-rel li {
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    outline: none;
    border: none;
    text-decoration: none;
    background: #254CCE;
    color: #fff;
    font-size: 24px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 40px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1;
    margin-right: 16px; }
    @media (prefers-reduced-motion: reduce) {
      #customize-controls li,
      #customize-controls-rel li {
        transition: none; } }
    #customize-controls li:hover, #customize-controls li:focus, #customize-controls li:active,
    #customize-controls-rel li:hover,
    #customize-controls-rel li:focus,
    #customize-controls-rel li:active {
      text-decoration: none;
      outline: none; }

#customize-controls-rel {
  justify-content: center;
  margin-top: 40px; }
  #customize-controls-rel li {
    margin: 0 8px; }

.-subscribe {
  background: #6BDD9C;
  color: #00101e;
  padding-top: 54px;
  padding-bottom: 60px; }
  .-subscribe-wrap {
    padding-right: 28px;
    padding-left: 28px; }
    .-subscribe-wrap form {
      flex-wrap: wrap; }
      .-subscribe-wrap form .wpcf7-form-control-wrap {
        flex: 0 0 auto;
        width: 50%; }
        @media (max-width: 767.98px) {
          .-subscribe-wrap form .wpcf7-form-control-wrap {
            flex: 0 0 auto;
            width: 100%; } }
      .-subscribe-wrap form .inline-submit {
        flex: 0 0 auto;
        width: calc(50% - 25px); }
        @media (max-width: 767.98px) {
          .-subscribe-wrap form .inline-submit {
            flex: 0 0 auto;
            width: 100%; } }

form {
  /*span,
    .wpcf7-form-control-wrap{ flex: 1; display: flex;}*/ }
  @media (min-width: 768px) {
    form {
      display: flex; } }
  form label {
    color: #1E2D61;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400; }
    form label + input,
    form label + textarea,
    form label + select {
      margin-top: 20px; }
  @media (min-width: 768px) {
    form .wpcf7-form-control-wrap + .inline-submit {
      /*flex: 1;*/
      font-weight: 500;
      margin-left: 25px; } }
  form input {
    background-color: #FFFEFF;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 20px;
    height: 40px;
    border: none;
    box-sizing: border-box;
    outline: none;
    box-shadow: none;
    color: #1E2D61;
    width: 100%;
    padding: 10px 14px;
    margin-bottom: 12px; }
    @media (min-width: 768px) {
      form input {
        font-weight: 500; }
        form input + input {
          margin-left: 25px; } }
    form input[type="submit"] {
      transition: all 0.2s ease-in-out;
      background-color: #010038;
      color: #FFFEFF;
      border: 1px solid #010038;
      font-weight: 500; }
      @media (prefers-reduced-motion: reduce) {
        form input[type="submit"] {
          transition: none; } }
      @media (min-width: 768px) {
        form input[type="submit"]:hover {
          color: #010038;
          background-color: #FFFEFF; } }
      form input[type="submit"]:valid {
        color: #FFFEFF; }
    @media (min-width: 768px) {
      form input:hover::placeholder {
        font-weight: 400;
        color: #010038; } }
    form input:focus, form input:valid {
      color: #010038; }
      form input:focus::placeholder, form input:valid::placeholder {
        color: #010038; }
    form input:invalid {
      color: #555554; }
    form input::placeholder {
      color: #555554;
      font-weight: 400; }
  form textarea {
    border-radius: 20px; }
  form select {
    outline: none;
    width: 100%;
    border: none;
    padding: 9px 17px;
    color: #1E2D61;
    font-weight: 500;
    border-radius: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Remove default arrow */
    background: #fff url("https://api.iconify.design/bx/bxs-down-arrow.svg?color=%231E2D61&width=16&height=50") no-repeat 95% center/contain; }

.wpcf7 form.failed .wpcf7-response-output {
  border-color: #A7080D;
  background-color: #A7080D;
  color: #FFFEFF; }

.wpcf7 form .wpcf7-response-output {
  flex: 0 0 auto;
  width: 100%;
  margin: 10px 0;
  border-radius: 16px; }

.-socials {
  color: #6BDD9C;
  padding: 0;
  margin: 0;
  font-size: 17px;
  font-size: 1.0625rem; }
  .-socials ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .-socials li {
    margin-top: 24px;
    margin-bottom: 10px;
    /*margin-right: 14px;
		&:first-child{margin-left: 0;}*/ }
  .-socials a {
    width: 34px;
    height: 34px;
    color: #6BDD9C;
    border: 2px solid #6BDD9C;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center; }

.custom-checkbox {
  position: absolute;
  opacity: 0; }
  .custom-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0 0 0 20px;
    left: auto;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 21px; }
  .custom-checkbox + label:before,
  .custom-checkbox + label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    margin-right: 9px;
    display: inline-block;
    vertical-align: text-top;
    width: 12px;
    height: 12px;
    border: 1px solid #010038;
    background-color: #FFFEFF; }
  .custom-checkbox + label:after {
    background-color: #FFFEFF;
    border: none;
    width: 8px;
    height: 8px;
    left: 2px;
    top: 5px; }
  .custom-checkbox:hover + label:after {
    background: #1E2D61; }
  .custom-checkbox:focus + label:before {
    /*box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);*/ }
  .custom-checkbox:checked + label:after {
    background-color: #6BDD9C; }
  .custom-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto; }
  .custom-checkbox:disabled + label:before {
    box-shadow: none;
    background: #ddd; }

.error-page {
  padding: 10rem 0;
  position: relative;
  text-align: center; }
  .error-page:before {
    color: #F3F6F9;
    content: attr(data-error);
    font-size: 40vw;
    font-weight: bold;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: -1; }
    @media (min-width: 992px) {
      .error-page:before {
        font-size: 20rem; } }

/*@import 'components/highlight';*/
.pd-l-menu {
  padding-left: 100px; }
  @media (max-width: 575.98px) {
    .pd-l-menu {
      padding-left: 40px; } }

.mg-l-menu {
  margin-left: 100px; }
  @media (max-width: 575.98px) {
    .mg-l-menu {
      margin-left: 40px; } }

.-offcanvas {
  transition: all 0.2s ease-in-out;
  position: fixed;
  /*z-index: 2100;*/
  z-index: 2010;
  top: 0;
  right: 0;
  background-color: #FFFEF8;
  color: #000000;
  width: 83.64%;
  max-width: 358px;
  height: 100%;
  padding: 10px 20px 30px 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  -webkit-transform: translate3d(100vw, 0, 0);
  -moz-transform: translate3d(100vw, 0, 0);
  -ms-transform: translate3d(100vw, 0, 0);
  -o-transform: translate3d(100vw, 0, 0);
  transform: translate3d(100vw, 0, 0);
  /*New menu*/
  /*@include media-breakpoint-down(md) {
    	&-nav{
    		li{
    			a{
        			&:before,
        			&:after{ display: none; }
        		}
    	    }
    	}
    }*/ }
  @media (prefers-reduced-motion: reduce) {
    .-offcanvas {
      transition: none; } }
  .-offcanvas-footer {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap; }
    .-offcanvas-footer a:hover, .-offcanvas-footer a:focus, .-offcanvas-footer a:active {
      color: #FFFEFF;
      background-color: #6BDD9C;
      border: 1px solid #6BDD9C;
      font-weight: 700; }
      .-offcanvas-footer a:hover span, .-offcanvas-footer a:focus span, .-offcanvas-footer a:active span {
        background-color: #6BDD9C;
        border-color: #6BDD9C; }
    @media (max-width: 575.98px) {
      .-offcanvas-footer a {
        margin-top: 5px;
        margin-bottom: 5px; } }
    @media (max-width: 991.98px) {
      .-offcanvas-footer .-btn-primary {
        padding: 12px 24px; }
        .-offcanvas-footer .-btn-primary + .-btn-primary {
          margin-left: 5px; } }
    @media (max-width: 575.98px) {
      .-offcanvas-footer .-btn-primary {
        font-size: 10px;
        padding: 10px;
        margin: 0; }
        .-offcanvas-footer .-btn-primary .iconify {
          font-size: 18px; } }
    @media (max-width: 991.98px) {
      .-offcanvas-footer .-socials li {
        margin-top: 10px; } }
    @media (max-width: 991.98px) {
      .-offcanvas-footer .-socials li + li {
        margin-left: 4px; } }
  .-offcanvas-languages {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-left: 20px; }
    @media (max-width: 991.98px) {
      .-offcanvas-languages {
        margin-left: 0;
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap; } }
    .-offcanvas-languages-label {
      font-size: 26px;
      display: flex;
      margin: auto;
      color: #1E2D61; }
    @media (max-width: 991.98px) {
      .-offcanvas-languages .dropdown {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-left: 0; } }
    .-offcanvas-languages .dropdown-menu {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-width: 94px;
      -webkit-transform: translate3d(-35px, 0, 0);
      -moz-transform: translate3d(-35px, 0, 0);
      -ms-transform: translate3d(-35px, 0, 0);
      -o-transform: translate3d(-35px, 0, 0);
      transform: translate3d(-35px, 0, 0); }
      .-offcanvas-languages .dropdown-menu:before {
        content: '';
        left: calc(50% - 8px);
        top: -8px;
        width: 0px;
        height: 0px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #1E2D61; }
        @media (max-width: 991.98px) {
          .-offcanvas-languages .dropdown-menu:before {
            visibility: hidden; } }
      @media (max-width: 991.98px) {
        .-offcanvas-languages .dropdown-menu {
          box-shadow: none;
          background-color: transparent;
          color: #1E2D61;
          position: relative;
          display: flex;
          flex-direction: row;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); } }
      .-offcanvas-languages .dropdown-menu li {
        display: flex;
        border-radius: 4px;
        overflow: hidden; }
        .-offcanvas-languages .dropdown-menu li a {
          display: flex;
          padding: 10px;
          line-height: 1.2; }
          .-offcanvas-languages .dropdown-menu li a:hover {
            background-color: transparent;
            color: #6BDD9C;
            border: none; }
    .-offcanvas-languages .dropdown:hover .dropdown-menu {
      display: flex;
      -webkit-transform: translate3d(-35px, 8px, 0);
      -moz-transform: translate3d(-35px, 8px, 0);
      -ms-transform: translate3d(-35px, 8px, 0);
      -o-transform: translate3d(-35px, 8px, 0);
      transform: translate3d(-35px, 8px, 0); }
  .-offcanvas-close {
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 25px;
    right: 30px;
    width: 28px;
    height: 28px;
    font-size: 30px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: transparent; }
    @media (prefers-reduced-motion: reduce) {
      .-offcanvas-close {
        transition: none; } }
    .-offcanvas-close:hover, .-offcanvas-close:focus, .-offcanvas-close:active {
      opacity: .8;
      background-color: transparent; }
    .-offcanvas-close-x {
      position: relative;
      width: 100%;
      height: 100%; }
      .-offcanvas-close-x:before, .-offcanvas-close-x:after {
        content: '';
        position: absolute;
        left: 50%;
        width: 2px;
        height: 100%;
        background-color: #6BDD9C; }
      .-offcanvas-close-x:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg); }
      .-offcanvas-close-x:after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    .menu-active .-offcanvas-close {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); }
    .-offcanvas-close [class*="icon"] {
      color: #000000;
      -webkit-transition: -webkit-transform .25s, opacity .25s;
      -moz-transition: -moz-transform .25s, opacity .25s;
      -ms-transition: -ms-transform .25s, opacity .25s;
      -o-transition: -0-transform .25s, opacity .25s;
      transition: transform .25s, opacity .25s; }
  .-offcanvas-brand {
    position: absolute;
    top: 24px;
    left: 38px;
    width: 50%;
    max-width: 95px; }
    @media (max-width: 991.98px) {
      .-offcanvas-brand {
        max-width: 60px; }
        .-offcanvas-brand .brand {
          max-width: 100%; } }
    .-offcanvas-brand a {
      display: flex; }
      @media (max-width: 991.98px) {
        .-offcanvas-brand a {
          align-items: center; } }
  .-offcanvas-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-right: 6px;
    padding-bottom: 10px;
    padding-left: 6px;
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    /*margin-top: 155px;*/
    /*z-index: 2;*/ }
    @media (max-width: 991.98px) {
      .-offcanvas-nav {
        margin-top: 60px; } }
    .-offcanvas-nav li {
      display: flex;
      height: auto;
      margin: 0 auto;
      /*margin-bottom: 0;*/
      width: 100%;
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: 400; }
      .-offcanvas-nav li a {
        transition: all 0.1s ease-in;
        display: flex;
        padding: 8px 12px;
        color: inherit;
        height: auto;
        width: 100%;
        justify-content: space-between;
        text-decoration: none; }
        @media (prefers-reduced-motion: reduce) {
          .-offcanvas-nav li a {
            transition: none; } }
        @media (max-width: 767.98px) {
          .-offcanvas-nav li a:after, .-offcanvas-nav li a:before {
            display: none; } }
      .-offcanvas-nav li.-btn {
        margin: 40px auto; }
        .-offcanvas-nav li.-btn a {
          border-radius: 0;
          padding: 20px;
          text-align: left;
          justify-content: flex-start; }
  .-offcanvas .dropdown {
    flex-direction: column;
    margin: 0 auto;
    position: relative; }
    .-offcanvas .dropdown-menu {
      position: absolute;
      display: none; }
    .-offcanvas .dropdown.open .dropdown-menu {
      position: relative;
      margin: 0;
      top: 0;
      padding: 0;
      background-color: #010038; }
      .-offcanvas .dropdown.open .dropdown-menu li {
        border-top: none; }
  .-offcanvas-active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    overflow-y: auto; }
  .-offcanvas::-webkit-scrollbar {
    width: 12px; }
  .-offcanvas::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #1E2D61; }
  .-offcanvas::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: #010038; }
  .-offcanvas-outter {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(34, 34, 34, 0.5);
    display: none;
    opacity: 0;
    -webkit-transition: opacity 25s;
    -moz-transition: opacity 25s;
    -ms-transition: opacity 25s;
    -o-transition: opacity 25s;
    transition: opacity 25s; }
    @media (max-width: 991.98px) {
      .menu-active .-offcanvas-outter {
        z-index: 1000;
        display: block;
        opacity: 1; } }
  .menu-active .-offcanvas {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  @media (min-width: 992px) {
    .-offcanvas {
      position: relative;
      width: auto;
      max-width: initial;
      height: auto;
      flex-direction: row;
      background-color: transparent;
      padding: 0;
      flex: 0 0 auto;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none; }
      .-offcanvas-header {
        display: none; }
      .-offcanvas-nav {
        margin: 0;
        flex-direction: row;
        align-items: center;
        /*flex: 1;*/
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: nowrap; }
        .-offcanvas-nav li {
          text-align: center; }
          .-offcanvas-nav li a {
            justify-content: center;
            white-space: nowrap;
            color: #1E2D61;
            border-bottom: 1px solid transparent; }
            .-offcanvas-nav li a:hover, .-offcanvas-nav li a:focus, .-offcanvas-nav li a:active {
              font-weight: 700;
              border-bottom-color: #6cdd9d; }
          .-offcanvas-nav li.active a, .-offcanvas-nav li.current_page_parent a {
            font-weight: 700;
            border-bottom: 1px solid #6cdd9d; }
      .-offcanvas-footer {
        margin: auto;
        flex-direction: row; }
        .-offcanvas-footer .-btn-primary {
          display: none; }
        .-offcanvas-footer .-socials {
          margin-left: 30px; }
          .-offcanvas-footer .-socials li {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 5px; }
          .-offcanvas-footer .-socials a {
            transition: all 0.25s ease-in;
            margin-top: 0;
            margin-bottom: 0; } }
        @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
          .-offcanvas-footer .-socials a {
            transition: none; } }

/*&-navbar{
		display: flex;
		@include make-col(8);
		margin-left: auto;

		&--nav{
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			width: 100%;
			height: 100%;
			@extend %default-list-clean-style;
			@extend %flex-dir-row;

			@include media-breakpoint-down(lg) {}

			li{
				list-style: none;
				padding: 0;
				font-size: 16px;
				@include font-rem(16);
				font-weight: $medium;
				text-align: center;
				line-height: 1.2;
				display: flex;
				align-items: center;
				height: 100%;
				margin-right: 26px;
				margin-left: 26px;

				&.dropdown > a{ 
					@include media-breakpoint-up(md) {
						padding-right: 0;
					}
				}

				a{
					color: inherit;
					display: flex;
					align-items: center;
					text-decoration: none;
					position: relative;
					white-space: nowrap;
					text-decoration: none;
					@include transition();

					@include media-breakpoint-down(lg) {
						padding-right: 10px;
						padding-left: 10px;
					}

				}
				&.active{ 
					a{text-decoration: underline;}
				}

				&.active,
				&.current-menu-item,
				&:hover{
					a{
						opacity: .8;
						@at-root {
							.bau-header-fixed &{
								text-decoration: none;
								color: $blue-base;
								opacity: 1;
							}
						}
						
					}

					.dropdown-menu a{
						opacity: 1;
						color: $gray-nav-base;
						&.active,
						&.current-menu-item,
						&:hover{ color: $blue-base; }

					}
				}
				&:first-child{ margin-left: 0;}

				&:last-child{ margin-right: 0;}
			}

			.-featured{
				a{ 
					@at-root {
						.bau-header-fixed &{
							
						}
					}
					&:active,
					&:focus,
					&:hover{
						
					}
				}


			}

			.-btn{
				a{ 

					border-radius: 20px;
					
					color: $white;
					padding: 11px 16px;
					font-size: 14px;
					@include font-rem(14);
					&:active,
					&:focus,
					&:hover{
						opacity: 1;
						background-color: $white;
						color: $purple-base;
					}

					@at-root {
						.bau-header-fixed &{
							background-color: $blue-base;
							border: 1px solid $blue-base;
							&:active,
							&:focus,
							&:hover{
								background-color: $white;
								color: $blue-base;
							}
						}
					}

						@include media-breakpoint-down(lg) {
							margin-left: inherit;
							flex: 0 0 auto;
							width: 100%;
							justify-content: center;
							padding: 5px 10px;
						}
				}

			}			
		}
	}*/
.cookiebar {
  padding: 0.6rem 0;
  display: -webkit-box;
  display: flex;
  text-align: center;
  background: #444;
  position: relative;
  font-weight: 700;
  /*Different style modes*/ }
  .cookiebar a {
    color: #f3b823;
    text-decoration: none; }
  .cookiebar .hide-cookiebar {
    position: absolute;
    right: 20px;
    top: 3px; }
    .cookiebar .hide-cookiebar svg {
      fill: white;
      width: 20px;
      height: 20px;
      position: relative;
      top: 2px; }
    .cookiebar .hide-cookiebar .hide-cookiebar-target {
      width: 24px;
      height: 24px;
      display: inline-block;
      border-radius: 50%;
      padding: 4px;
      -webkit-transition: all ease 0.3s;
      transition: all ease 0.3s;
      text-align: center; }
    .cookiebar .hide-cookiebar:hover .hide-cookiebar-target {
      background: rgba(255, 255, 255, 0.9); }
      .cookiebar .hide-cookiebar:hover .hide-cookiebar-target svg {
        fill: #444; }
  .cookiebar-bt-fix {
    color: #fff;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
    position: fixed;
    width: 100%;
    height: auto;
    bottom: 0;
    -webkit-transition: all .8s linear;
    -moz-transition: all .8s linear;
    -ms-transition: all .8s linear;
    -o-transition: all .8s linear;
    transition: all .8s linear; }
    .cookiebar-bt-fix.no-cookie-yet {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
  .cookiebar-left-tooltip {
    position: fixed;
    bottom: 20px;
    left: 30px;
    width: 295px;
    padding: 20px 20px 18px 17px;
    background: #fff;
    /*box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 14px;*/
    border-radius: 5px;
    margin: 15px auto;
    color: #444;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    z-index: 999;
    -webkit-transform: translateX(-200%);
    -moz-transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -o-transform: translateX(-200%);
    transform: translateX(-200%);
    -webkit-transition: all .8s linear;
    -moz-transition: all .8s linear;
    -ms-transition: all .8s linear;
    -o-transition: all .8s linear;
    transition: all .8s linear; }
    .cookiebar-left-tooltip.no-cookie-yet {
      animation-name: bounceInLeft;
      animation-delay: 1s;
      animation-duration: 1s;
      animation-fill-mode: both; }
    .cookiebar-left-tooltip .title {
      display: flex;
      font-style: normal;
      font-weight: bold;
      color: #686868;
      line-height: 1.2;
      margin-bottom: 10px; }
    .cookiebar-left-tooltip p {
      font-size: inherit;
      font-weight: inherit;
      margin-bottom: 10px; }
    .cookiebar-left-tooltip a {
      color: #6BDD9C; }
    .cookiebar-left-tooltip .hide-cookiebar .hide-cookiebar-target svg {
      fill: #444; }
    .cookiebar-left-tooltip .hide-cookiebar:hover .hide-cookiebar-target {
      background: rgba(0, 0, 0, 0.06);
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 14px;
      /*svg {
                      fill: #eee;
                    }*/ }
    .cookiebar-left-tooltip .accept-cookies {
      background-color: #6BDD9C;
      color: #fff;
      border-radius: 30px;
      padding: 4px 18px;
      margin-left: auto;
      font-weight: bold;
      font-size: 14px; }
  .cookiebar-bottom-tooltip {
    box-shadow: 0 -1px 3px #eaf0f6;
    border-top: 1px solid #cbd6e2;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    color: #00101e;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    z-index: 2147483647;
    -webkit-transform: translateY(200%);
    -moz-transform: translateY(200%);
    -ms-transform: translateY(200%);
    -o-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-transition: all .4s linear;
    -moz-transition: all .4s linear;
    -ms-transition: all .4s linear;
    -o-transition: all .4s linear;
    transition: all .4s linear; }
    .cookiebar-bottom-tooltip .container-cookiebox {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      padding: 20px; }
      @media (max-width: 767.98px) {
        .cookiebar-bottom-tooltip .container-cookiebox {
          padding-top: 30px; } }
    .cookiebar-bottom-tooltip .bottom-act {
      display: flex;
      justify-content: flex-end; }
      @media (max-width: 767.98px) {
        .cookiebar-bottom-tooltip .bottom-act {
          justify-content: center; } }
    .cookiebar-bottom-tooltip.no-cookie-yet {
      animation-name: bounceInUp;
      animation-delay: 1s;
      animation-duration: 1s;
      animation-fill-mode: both;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
    .cookiebar-bottom-tooltip .title {
      display: flex;
      font-style: normal;
      font-weight: bold;
      color: #686868;
      line-height: 1.2;
      margin-bottom: 10px; }
    .cookiebar-bottom-tooltip p {
      font-size: inherit;
      font-weight: inherit;
      margin-bottom: 12px; }
    .cookiebar-bottom-tooltip a {
      color: #6BDD9C; }
    .cookiebar-bottom-tooltip .hide-cookiebar .hide-cookiebar-target {
      padding: 0; }
      .cookiebar-bottom-tooltip .hide-cookiebar .hide-cookiebar-target svg {
        fill: #444; }
    .cookiebar-bottom-tooltip .hide-cookiebar:hover .hide-cookiebar-target {
      background: #6BDD9C;
      color: #FFFEFF;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 14px; }
      .cookiebar-bottom-tooltip .hide-cookiebar:hover .hide-cookiebar-target svg {
        fill: #FFFEFF; }
    .cookiebar-bottom-tooltip .accept-cookies,
    .cookiebar-bottom-tooltip .refuse-cookies {
      border-radius: 3px;
      padding: 10px 16px;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      text-decoration: none;
      border: 1px solid transparent;
      margin: 6px; }
    .cookiebar-bottom-tooltip .accept-cookies {
      background-color: #6BDD9C;
      border-color: #6BDD9C;
      color: #fff; }
      .cookiebar-bottom-tooltip .accept-cookies:hover {
        background-color: #1E2D61;
        border-color: #1E2D61; }
    .cookiebar-bottom-tooltip .refuse-cookies {
      background-color: transparent;
      border-color: #6BDD9C;
      color: #6BDD9C; }
      .cookiebar-bottom-tooltip .refuse-cookies:hover {
        color: #FFFEFF;
        background-color: #010038;
        border-color: #010038; }

/*animations*/
@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.tns-outer {
  padding: 0 !important; }
  .tns-outer [hidden] {
    display: none !important; }
  .tns-outer [aria-controls], .tns-outer [data-action] {
    cursor: pointer; }

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s; }
  .tns-slider > .tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.tns-horizontal.tns-subpixel {
  white-space: nowrap; }
  .tns-horizontal.tns-subpixel > .tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal; }

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both; }

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left; }

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%; }

.tns-no-calc {
  position: relative;
  left: 0; }

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; }
  .tns-gallery > .tns-item {
    position: absolute;
    left: -100%;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s; }
  .tns-gallery > .tns-slide-active {
    position: relative;
    left: auto !important; }
  .tns-gallery > .tns-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s; }

.tns-autowidth {
  display: inline-block; }

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6; }
  .tns-lazy-img.tns-complete {
    opacity: 1; }

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s; }

.tns-ovh {
  overflow: hidden; }

.tns-visually-hidden {
  position: absolute;
  left: -10000em; }

.tns-transparent {
  opacity: 0;
  visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0; }

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1; }

.tns-vpfix {
  white-space: nowrap; }
  .tns-vpfix > div, .tns-vpfix > li {
    display: inline-block; }

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden; }

.tns-t-ct {
  width: 2333.33333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0; }
  .tns-t-ct:after {
    content: '';
    display: table;
    clear: both; }
  .tns-t-ct > div {
    width: 1.42857%;
    width: -webkit-calc(100% / 70);
    width: -moz-calc(100% / 70);
    width: calc(100% / 70);
    height: 10px;
    float: left; }

/*@import 'blog/index';
@import 'blog/articles';
@import 'blog/latest';
@import 'blog/single';
@import 'blog/slider';*/
.-home {
  /*First*/
  /*Second*/
  /*Third*/
  /*Fourth*/ }
  .-home-first {
    padding-top: 109px;
    display: flex;
    min-height: 100vh;
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    /*background-color: #26294d;*/
    background-color: #131428;
    /*background-attachment: fixed;*/ }
    @media (max-width: 767.98px) {
      .-home-first {
        /*min-height: auto;
			background-position: center 40%;
			background-size: 140%;*/
        background-position: center;
        padding-top: 60px; } }
    .-home-first-wrap {
      display: flex;
      flex-direction: row-reverse;
      align-items: center; }
      @media (max-width: 767.98px) {
        .-home-first-wrap {
          flex-direction: column; } }
    .-home-first-image, .-home-first-content {
      /*@include make-col(6);*/
      /*@include media-breakpoint-down(md) {
				width: 100%;
			}*/ }
    .-home-first-image figure {
      margin: 0; }
    @media (max-width: 767.98px) {
      .-home-first-image img {
        max-width: 75%;
        margin: 0 auto; } }
    .-home-first-content {
      flex: 0 0 auto;
      width: 58.33333%;
      padding-right: 10%;
      margin-top: 10vh;
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: 400;
      margin-right: auto; }
      @media (max-width: 1399.98px) {
        .-home-first-content {
          margin-top: 2vh; } }
      @media (max-width: 767.98px) {
        .-home-first-content {
          padding-right: 0;
          width: 70%; } }
      .-home-first-content h1, .-home-first-content h2, .-home-first-content h3 {
        color: #FFFEFF;
        font-weight: 700;
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 1;
        margin-bottom: 28px; }
        @media (max-width: 1399.98px) {
          .-home-first-content h1, .-home-first-content h2, .-home-first-content h3 {
            font-size: 34px;
            font-size: 2.125rem; } }
        @media (max-width: 767.98px) {
          .-home-first-content h1, .-home-first-content h2, .-home-first-content h3 {
            font-size: 30px;
            font-size: 1.875rem;
            line-height: 1.2;
            margin-bottom: 15px; } }
        @media (max-width: 575.98px) {
          .-home-first-content h1, .-home-first-content h2, .-home-first-content h3 {
            font-size: 18px;
            font-size: 1.125rem;
            /*line-height: 1.2;
					margin-bottom: 15px*/ } }
      .-home-first-content p {
        color: #FFFEFF;
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 1.66;
        font-weight: 400; }
        @media (max-width: 1399.98px) {
          .-home-first-content p {
            font-size: 16px;
            font-size: 1rem; } }
        @media (max-width: 767.98px) {
          .-home-first-content p {
            font-size: 16px;
            font-size: 1rem;
            line-height: 1.5; } }
        @media (max-width: 575.98px) {
          .-home-first-content p {
            font-size: 14px;
            font-size: 0.875rem; } }
      .-home-first-content .-btn-primary {
        margin-top: 20px;
        padding: 15px 50px; }
        @media (max-width: 767.98px) {
          .-home-first-content .-btn-primary {
            display: block;
            margin: 30px 12% 0;
            text-align: center; } }
  .-home-second {
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-color: #f1715c; }
    @media (max-width: 767.98px) {
      .-home-second {
        background-size: 0;
        padding-top: 15vh; } }
    @media (max-width: 575.98px) {
      .-home-second {
        background-size: 0;
        padding-top: 20px; } }
    .-home-second-wrap {
      display: flex;
      flex-direction: row;
      padding-top: 80px;
      padding-bottom: 95px; }
      @media (max-width: 991.98px) {
        .-home-second-wrap {
          flex-direction: column; } }
      @media (max-width: 575.98px) {
        .-home-second-wrap {
          padding-top: 60px;
          padding-bottom: 60px; } }
    .-home-second-image, .-home-second-form, .-home-second-content {
      flex: 0 0 auto;
      width: 66.66667%;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .-home-second-image, .-home-second-form, .-home-second-content {
          width: 100%; } }
    .-home-second-image, .-home-second-form {
      display: flex;
      align-items: center; }
      .-home-second-image h2, .-home-second-form h2 {
        color: #fff;
        text-align: center; }
        @media (max-width: 575.98px) {
          .-home-second-image h2, .-home-second-form h2 {
            font-size: 24px;
            font-size: 1.5rem; } }
    .-home-second-form {
      flex-direction: column;
      align-items: flex-start; }
    .-home-second-content {
      padding-left: 70px; }
      @media (max-width: 991.98px) {
        .-home-second-content {
          padding-left: 0; } }
      .-home-second-content.nopd {
        padding-left: 0; }
      .-home-second-content h2, .-home-second-content h3 {
        font-size: 33px;
        font-size: 2.0625rem;
        line-height: 1.1;
        margin-bottom: 11px;
        font-weight: 700; }
      .-home-second-content h4 {
        color: #1E2D61;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 11px; }
      .-home-second-content p {
        color: #000000;
        font-size: 14px;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 2.14;
        margin-bottom: 20px; }
        .-home-second-content p + h2,
        .-home-second-content p + h3 {
          margin-top: 40px; }
        .-home-second-content p strong {
          color: #6BDD9C; }
      @media (max-width: 767.98px) {
        .-home-second-content .-btn-primary {
          display: block;
          margin: 30px 12% 0;
          text-align: center; } }
  .-home-third {
    padding-top: 106px;
    padding-bottom: 64px;
    background-color: rgba(107, 221, 156, 0.3); }
    @media (max-width: 767.98px) {
      .-home-third {
        background-size: 0;
        padding-top: 15vh; } }
    .-home-third-wrap {
      display: flex;
      flex-direction: row; }
      @media (max-width: 767.98px) {
        .-home-third-wrap {
          flex-direction: column; } }
    .-home-third-image, .-home-third-content {
      flex: 0 0 auto;
      width: 50%;
      position: relative;
      z-index: 1; }
      @media (max-width: 767.98px) {
        .-home-third-image, .-home-third-content {
          width: 100%; } }
    .-home-third-image img {
      margin-left: auto; }
      @media (max-width: 767.98px) {
        .-home-third-image img {
          margin-top: 40px;
          margin-right: auto; } }
    .-home-third-content {
      position: relative;
      z-index: 1;
      /*@include media-breakpoint-down(md) {
	        position: relative;
	        z-index: 1;
	        }*/ }
      .-home-third-content h2, .-home-third-content h3 {
        font-size: 33px;
        font-size: 2.0625rem;
        line-height: 1.1;
        margin-bottom: 11px;
        font-weight: 700; }
      .-home-third-content h4 {
        color: #1E2D61;
        font-size: 18px;
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 11px; }
      .-home-third-content p {
        font-size: 14px;
        font-size: 0.875rem;
        font-weight: 400;
        color: #1E2D61; }
        .-home-third-content p strong {
          font-weight: 800; }
          .-home-third-content p strong a {
            color: #1E2D61;
            text-decoration: none;
            font-weight: inherit; }
            .-home-third-content p strong a:hover, .-home-third-content p strong a:focus, .-home-third-content p strong a:active {
              text-decoration: underline; }
        .-home-third-content p + h2, .-home-third-content p + h3, .-home-third-content p + h4 {
          margin-top: 40px; }
      .-home-third-content .-btn-white {
        margin-top: 40px;
        display: block;
        max-width: 515px; }
        @media (max-width: 767.98px) {
          .-home-third-content .-btn-white {
            padding: 17px; }
            .-home-third-content .-btn-white br {
              display: none; } }
  .-home-fourth {
    /*padding-top: 98px;
		padding-bottom: 103px;*/
    padding-top: 60px;
    padding-bottom: 60px;
    align-items: center;
    background-position-x: 90%;
    background-position-y: top;
    background-repeat: no-repeat;
    position: relative; }
    @media (max-width: 767.98px) {
      .-home-fourth {
        padding-top: 15vh;
        padding-bottom: 5vh; } }
    .-home-fourth:before {
      content: '';
      position: absolute;
      top: -30%;
      right: 0;
      width: 100%;
      height: 130%;
      /*background-image: url('../images/big-leaf.png');*/
      background-position-x: 83%;
      background-position-y: top;
      background-repeat: no-repeat; }
      @media (max-width: 991.98px) {
        .-home-fourth:before {
          top: -100px;
          left: 0;
          width: 100%;
          height: calc(100% + 100px); } }
    .-home-fourth .container {
      position: relative; }
    .-home-fourth-wrap {
      overflow: hidden; }
      .-home-fourth-wrap p {
        line-height: 1.8; }
    .-home-fourth-content h2, .-home-fourth-content h3 {
      font-size: 33px;
      font-size: 2.0625rem;
      line-height: 1.1;
      margin-bottom: 11px;
      font-weight: 700; }
    .-home-fourth-content h4 {
      color: #1E2D61;
      font-size: 22px;
      font-size: 1.375rem;
      font-weight: 700;
      margin-bottom: 11px; }
    .-home-fourth-rpt {
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px; }
      @media (max-width: 991.98px) {
        .-home-fourth-rpt {
          margin-top: 60px; } }
      @media (max-width: 767.98px) {
        .-home-fourth-rpt {
          margin-top: 30px; } }
      .-home-fourth-rpt-item {
        flex: 0 0 auto;
        width: calc(33.33333% - 20px);
        padding: 0 10px;
        margin-bottom: 48px; }
        @media (max-width: 991.98px) {
          .-home-fourth-rpt-item {
            width: calc(50% - 20px);
            margin-bottom: 30px; } }
        @media (max-width: 767.98px) {
          .-home-fourth-rpt-item {
            width: 100%;
            padding: 0; } }
        .-home-fourth-rpt-item figure {
          margin-bottom: 17px;
          min-height: 91px;
          display: flex;
          align-items: center; }
        .-home-fourth-rpt-item .figure {
          margin-bottom: 17px;
          min-height: 91px;
          display: flex;
          align-items: center; }
          .-home-fourth-rpt-item .figure .iconify {
            font-size: 60px;
            color: #E35207; }
        .-home-fourth-rpt-item h3 {
          color: #1E2D61;
          font-size: 20px;
          font-size: 1.25rem;
          font-weight: 700; }
        .-home-fourth-rpt-item p {
          color: #000000;
          font-size: 14px;
          font-size: 0.875rem; }

/*Helpers*/
.pl70 {
  padding-left: 70px; }
  @media (max-width: 991.98px) {
    .pl70 {
      padding-left: 0; } }

/* alpha form */
.-alpha-form-wrap {
  flex: 0 0 auto;
  width: 100%;
  margin-top: 20px; }
  .-alpha-form-wrap form {
    display: flex;
    flex-direction: column;
    border-radius: 22px;
    background-color: #FFFEFF;
    padding: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16); }
    @media (max-width: 767.98px) {
      .-alpha-form-wrap form {
        padding: 12px 16px;
        box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.36); } }
    .-alpha-form-wrap form fieldset {
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; }
      .-alpha-form-wrap form fieldset.columns-2 {
        flex-wrap: wrap; }
        @media (min-width: 992px) and (max-width: 1199.98px) {
          .-alpha-form-wrap form fieldset.columns-2 {
            margin-bottom: 0; } }
        @media (max-width: 767.98px) {
          .-alpha-form-wrap form fieldset.columns-2 {
            margin-bottom: 0; } }
        .-alpha-form-wrap form fieldset.columns-2 input {
          flex: 0 0 auto;
          width: calc(50% - 4px); }
        .-alpha-form-wrap form fieldset.columns-2 .wpcf7-form-control-wrap {
          flex: 0 0 auto;
          width: calc(50% - 4px);
          /*@include media-breakpoint-only(xl) {
						background-color: blue;
					}*/ }
          @media (min-width: 992px) and (max-width: 1199.98px) {
            .-alpha-form-wrap form fieldset.columns-2 .wpcf7-form-control-wrap {
              width: 100%;
              margin-bottom: 10px; } }
          @media (max-width: 767.98px) {
            .-alpha-form-wrap form fieldset.columns-2 .wpcf7-form-control-wrap {
              width: 100%;
              margin-bottom: 10px; } }
          .-alpha-form-wrap form fieldset.columns-2 .wpcf7-form-control-wrap > input {
            flex: 0 0 auto;
            width: 100%; }
    .-alpha-form-wrap form .inputs-list {
      list-style: none;
      margin: 20px 0 0;
      padding: 0; }
    .-alpha-form-wrap form input {
      border: 1px solid #E35207;
      color: #010038;
      font-size: 14px;
      font-size: 0.875rem;
      padding: 6px 12px;
      border-radius: 54px;
      height: 44px;
      margin-bottom: 6px; }
      @media (max-width: 767.98px) {
        .-alpha-form-wrap form input {
          width: 100%; } }
    .-alpha-form-wrap form input + input {
      margin-left: 0; }
    .-alpha-form-wrap form textarea {
      resize: none;
      border-radius: 22px;
      border: 1px solid #6BDD9C;
      padding: 19px 16px;
      height: 121px;
      width: 100%; }
    .-alpha-form-wrap form button[type="submit"], .-alpha-form-wrap form input[type="submit"] {
      margin-top: 10px;
      margin-bottom: 10px;
      border: 1px solid #010038;
      padding: 0; }
      .-alpha-form-wrap form button[type="submit"]:hover, .-alpha-form-wrap form button[type="submit"]:focus, .-alpha-form-wrap form button[type="submit"]:active, .-alpha-form-wrap form input[type="submit"]:hover, .-alpha-form-wrap form input[type="submit"]:focus, .-alpha-form-wrap form input[type="submit"]:active {
        color: #FFFEFF;
        background-color: #E35207;
        border-color: #E35207; }
    .-alpha-form-wrap form input[type="checkbox"] {
      padding: 0;
      height: auto;
      margin-right: 10px;
      width: auto; }
      @media (max-width: 767.98px) {
        .-alpha-form-wrap form input[type="checkbox"] {
          width: auto; } }

.-about {
  /*First*/
  /*Second*/
  /*Content text*/ }
  .-about-highlight {
    min-height: 537px;
    width: 100%;
    display: flex;
    overflow: hidden;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative; }
    @media (max-width: 991.98px) {
      .-about-highlight {
        min-height: 40vh; } }
    @media (max-width: 767.98px) {
      .-about-highlight {
        min-height: 45vh; } }
    .-about-highlight:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: url("../images/hg-about-pe.png");
      background-size: cover;
      background-position: bottom center;
      background-repeat: no-repeat; }
  .-about-first {
    padding-top: 34px;
    padding-bottom: 130px; }
    @media (max-width: 991.98px) {
      .-about-first {
        padding-bottom: 30px; } }
    @media (max-width: 767.98px) {
      .-about-first {
        padding-top: 5vh;
        padding-bottom: 5vh; } }
    .-about-first h1 {
      font-size: 44px;
      color: #1E2D61;
      font-weight: 700;
      line-height: 1.15;
      margin-top: 68px; }
      @media (max-width: 991.98px) {
        .-about-first h1 {
          margin-top: 10px;
          margin-bottom: 30px; } }
      @media (max-width: 767.98px) {
        .-about-first h1 {
          font-size: 36px;
          margin-bottom: 20px; } }
    .-about-first h3 {
      font-size: 30px;
      color: #1E2D61;
      font-weight: 700;
      margin-bottom: 0; }
      @media (max-width: 767.98px) {
        .-about-first h3 {
          font-size: 22px; } }
      .-about-first h3 + p {
        margin-top: 40px; }
    .-about-first h4 {
      font-size: 22px;
      font-weight: 500;
      color: #6BDD9C;
      line-height: 1.95; }
      @media (max-width: 767.98px) {
        .-about-first h4 {
          font-size: 18px;
          line-height: 1.4; } }
      .-about-first h4 + p {
        margin-top: 40px; }
        @media (max-width: 767.98px) {
          .-about-first h4 + p {
            margin-top: 30px; } }
    .-about-first p {
      font-size: 18px;
      font-size: 1.125rem;
      color: #000;
      font-weight: 400;
      margin-bottom: 40px; }
      @media (max-width: 767.98px) {
        .-about-first p {
          font-size: 16px;
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 20px; } }
    .-about-first li {
      font-size: 16px;
      color: #000;
      font-weight: 400;
      margin-bottom: .5rem; }
      @media (max-width: 767.98px) {
        .-about-first li {
          font-size: 14px; } }
    .-about-first img {
      margin-bottom: 51px; }
      @media (max-width: 767.98px) {
        .-about-first img {
          margin-bottom: 30px; } }
    .-about-first.-default p {
      margin-bottom: 1em; }
  .-about-second {
    background-color: rgba(102, 221, 156, 0.2);
    padding-top: 144px;
    padding-bottom: 115px; }
    @media (max-width: 991.98px) {
      .-about-second {
        padding-top: 10vh;
        padding-bottom: 10vh; } }
    .-about-second-title {
      display: block;
      text-align: center;
      margin-bottom: 157px; }
      @media (max-width: 991.98px) {
        .-about-second-title {
          margin-bottom: 30px; } }
    .-about-second-slider {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      padding-left: 5vw; }
      @media (max-width: 991.98px) {
        .-about-second-slider {
          padding-bottom: 30px; } }
      @media (min-width: 768px) {
        .-about-second-slider::-webkit-scrollbar {
          /*width: 12px;*/
          display: none; }
        .-about-second-slider::-webkit-scrollbar-track {
          display: none;
          /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
				    border-radius: 10px;
				    background-color: $blue-dark;*/ }
        .-about-second-slider::-webkit-scrollbar-thumb {
          display: none;
          /*border-radius: 10px;
				    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
				    background-color: $blue-base;*/ } }
    .-about-second-sl-item {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      flex: 0 0 auto;
      width: calc(100vw / 8); }
      @media (max-width: 991.98px) {
        .-about-second-sl-item {
          width: calc(100vw / 4); } }
      @media (max-width: 767.98px) {
        .-about-second-sl-item {
          width: calc(100vw / 1.7); } }
      .-about-second-sl-item:before, .-about-second-sl-item:after {
        content: '';
        position: absolute;
        background-color: #1E2D61;
        width: 50%;
        height: 1px;
        top: 44px;
        left: 0;
        z-index: -1; }
      .-about-second-sl-item:after {
        left: 50%; }
      .-about-second-sl-item:first-child:before {
        opacity: 0; }
      .-about-second-sl-item:last-child:after {
        opacity: 0; }
      .-about-second-sl-item .month {
        font-size: 16px;
        font-weight: 700;
        color: #1E2D61;
        margin-bottom: 6px; }
      .-about-second-sl-item .ico {
        margin-bottom: 14px; }
      .-about-second-sl-item .iconify {
        font-size: 27px;
        color: #6BDD9C;
        background-color: #FFFEFF;
        border-radius: 27px; }
      .-about-second-sl-item .text {
        font-size: 16px;
        font-weight: 400;
        color: #1E2D61;
        text-align: center;
        /*margin-bottom: 6px;*/ }
        .-about-second-sl-item .text ul {
          text-align: left; }
          .-about-second-sl-item .text ul li {
            position: relative;
            padding-left: 20px; }
            .-about-second-sl-item .text ul li:before {
              content: '✓';
              position: absolute;
              left: 0;
              /*width: 20px;
							*height: 20px;*/
              font-size: 15px;
              /*display: flex;
							align-items: center;
							justify-content: center;*/
              font-weight: bold;
              color: #1E2D61;
              margin-right: 5px; }
            .-about-second-sl-item .text ul li + li {
              margin-top: 10px; }
    .-about-second-sl-wrap {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      flex: 0 0 auto;
      padding-right: 20px;
      padding-left: 20px; }
    .-about-second-sl-navs {
      margin-top: 47px;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1; }
      @media (max-width: 991.98px) {
        .-about-second-sl-navs {
          display: none; } }
      .-about-second-sl-navs button {
        font-size: 35px;
        color: #6BDD9C;
        position: relative;
        outline: none;
        display: inline-block;
        border: none;
        text-decoration: none;
        background: transparent;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        line-height: 1;
        opacity: .5;
        padding: 0;
        margin: 0 6px;
        -webkit-transition: all .2s ease-in;
        -moz-transition: all .2s ease-in;
        -ms-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
        transition: all .2s ease-in; }
        .-about-second-sl-navs button:hover, .-about-second-sl-navs button:focus {
          text-decoration: none;
          outline: none;
          opacity: 1;
          background: transparent; }
  .-about-content-text {
    padding-top: 123px;
    padding-bottom: 123px;
    position: relative; }
    @media (max-width: 767.98px) {
      .-about-content-text {
        padding-top: 10vh;
        padding-bottom: 10vh; } }
    .-about-content-text:before {
      content: '';
      position: absolute;
      top: -30%;
      right: 0;
      width: 100%;
      height: 130%;
      background-image: url("../images/big-leaf.png");
      background-position-x: 83%;
      background-position-y: top;
      background-repeat: no-repeat; }
      @media (max-width: 991.98px) {
        .-about-content-text:before {
          top: -100px;
          left: 0;
          width: 100%;
          height: calc(100% + 100px); } }
    .-about-content-text p {
      font-size: 14px;
      font-weight: 400;
      color: #000;
      line-height: 2.42; }

/*Roadmap*/
@media (min-width: 992px) {
  .count-7 {
    visibility: hidden; } }

@media (min-width: 992px) {
  .scroll-container + .scroll-container {
    margin-top: 60px; } }

@media (max-width: 767.98px) {
  .scroll-container + .scroll-container {
    margin-top: 40px; } }

/*Up lg*/
@media (min-width: 992px) {
  .count-0, .count-1, .count-2, .count-3, .count-4, .count-5, .count-6 {
    visibility: hidden; } }

@media (min-width: 992px) {
  .nav-hide {
    visibility: hidden;
    height: 0;
    margin: 0; } }

.-jobs-highlight {
  min-height: 537px;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative; }
  @media (max-width: 767.98px) {
    .-jobs-highlight {
      min-height: 40vh; } }
  .-jobs-highlight:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url("../images/hg-about-pe.png");
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat; }

.-jobs-first {
  padding-top: 102px;
  padding-bottom: 130px;
  position: relative; }
  @media (max-width: 991.98px) {
    .-jobs-first {
      padding-top: 7vh;
      padding-bottom: 10vh; } }
  @media (max-width: 767.98px) {
    .-jobs-first {
      padding-top: 5vh;
      padding-bottom: 10vh; } }
  .-jobs-first:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/big-leaf.png");
    background-position-x: 70%;
    background-position-y: top;
    background-repeat: no-repeat; }
  .-jobs-first .container {
    position: relative; }
  .-jobs-first h1 {
    font-size: 44px;
    font-size: 2.75rem;
    color: #1E2D61;
    font-weight: 700;
    line-height: 1.15; }
    @media (max-width: 767.98px) {
      .-jobs-first h1 {
        font-size: 36px;
        font-size: 2.25rem;
        margin-bottom: 20px; } }
  .-jobs-first h3 {
    font-size: 30px;
    font-size: 1.875rem;
    color: #1E2D61;
    font-weight: 800;
    margin-bottom: 0; }
  .-jobs-first h4 {
    font-size: 22px;
    font-size: 1.375rem;
    font-weight: 500;
    color: #6BDD9C;
    line-height: 1.95; }
    .-jobs-first h4 + p {
      margin-top: 40px; }
  .-jobs-first p {
    font-size: 18px;
    font-size: 1.125rem;
    color: #000000;
    font-weight: 400; }
    @media (max-width: 767.98px) {
      .-jobs-first p {
        font-size: 14px;
        font-size: 0.875rem; } }
  .-jobs-first-content h3:first-of-type {
    /*padding-top: calc(156px + 128px);*/
    margin-top: 15vh;
    margin-bottom: 40px; }
    @media (max-width: 991.98px) {
      .-jobs-first-content h3:first-of-type {
        margin-top: 0;
        margin-bottom: 20px; } }
    @media (max-width: 767.98px) {
      .-jobs-first-content h3:first-of-type {
        font-size: 20px; } }

.-jobs-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 30px; }
  .-jobs-list li {
    padding: 28px 36px 17px;
    border-radius: 19px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
    margin-bottom: 50px;
    background-color: #FFFEFF; }
    @media (max-width: 767.98px) {
      .-jobs-list li {
        padding: 20px 14px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.26);
        margin-bottom: 30px; } }
    .-jobs-list li .-btn-primary {
      margin-top: 0;
      display: block;
      text-align: center; }

.-jobs-title {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.38;
  margin-bottom: 10px; }
  @media (max-width: 767.98px) {
    .-jobs-title {
      font-size: 16px;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.38;
      margin-bottom: 10px; } }

.-jobs-desc {
  line-height: 1.38;
  margin-bottom: 20px;
  font-size: 16px;
  font-size: 1rem; }

.-contact-highlight {
  min-height: 537px;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative; }
  @media (max-width: 991.98px) {
    .-contact-highlight {
      min-height: 40vh; } }
  .-contact-highlight:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url("../images/hg-about-pe.png");
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat; }

.-contact-first {
  padding-top: 100px;
  padding-bottom: 230px;
  position: relative; }
  @media (max-width: 767.98px) {
    .-contact-first {
      padding-top: 5vh;
      padding-bottom: 10vh; } }
  .-contact-first:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/big-leaf.png");
    background-position-x: 10vw;
    background-position-y: 0%;
    background-repeat: no-repeat; }
  .-contact-first .container {
    position: relative; }
  .-contact-first h1 {
    font-size: 44px;
    font-size: 2.75rem;
    color: #1E2D61;
    font-weight: 700;
    line-height: 1.15;
    margin-bottom: 27px; }
    @media (max-width: 767.98px) {
      .-contact-first h1 {
        font-size: 36px;
        font-size: 2.25rem;
        margin-bottom: 20px; } }
  .-contact-first p {
    font-size: 18px;
    font-size: 1.125rem;
    color: #000000;
    font-weight: 400;
    margin-bottom: 40px; }
    @media (max-width: 767.98px) {
      .-contact-first p {
        font-size: 16px;
        font-size: 1rem; } }
    .-contact-first p a {
      font-size: inherit;
      color: #6BDD9C;
      font-weight: 500;
      text-decoration: none;
      /*@include media-breakpoint-down(md) {
					font-size: inherit;
				}*/ }

.-contact-form-wrap form {
  display: flex;
  flex-direction: column;
  border-radius: 22px;
  background-color: #FFFEFF;
  padding: 38px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16); }
  @media (max-width: 767.98px) {
    .-contact-form-wrap form {
      padding: 20px 16px;
      box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.26); } }
  .-contact-form-wrap form input {
    border: 1px solid #6BDD9C;
    color: #010038;
    font-size: 14px;
    font-size: 0.875rem;
    padding: 16px;
    border-radius: 54px;
    height: 54px;
    margin-bottom: 23px; }
    .-contact-form-wrap form input + input {
      margin-left: 0; }
  .-contact-form-wrap form textarea {
    resize: none;
    border-radius: 22px;
    border: 1px solid #6BDD9C;
    padding: 19px 16px;
    height: 121px;
    width: 100%; }
  .-contact-form-wrap form button[type="submit"], .-contact-form-wrap form input[type="submit"] {
    margin-top: 27px;
    margin-bottom: 10px;
    border: 1px solid #010038; }
    .-contact-form-wrap form button[type="submit"]:hover, .-contact-form-wrap form button[type="submit"]:focus, .-contact-form-wrap form button[type="submit"]:active, .-contact-form-wrap form input[type="submit"]:hover, .-contact-form-wrap form input[type="submit"]:focus, .-contact-form-wrap form input[type="submit"]:active {
      background-color: #FFFEFF;
      border-color: #010038;
      color: #010038; }

/*@import 'pages/blog-single';
@import 'pages/404';*/
/*@import 'fixing/custom-media-queries';*/
