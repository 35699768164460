@mixin background-full ($bg-position: 50% 50%, $bg-repeat: no-repeat, $bg-size: cover) {
	background-position: $bg-position;
	background-repeat: $bg-repeat;
	background-size: $bg-size;
}

@mixin full-element($position: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0, $z-index: 999) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
	z-index: $z-index;
}

@mixin font-icon ($icon) {
	&:before {
		content: $icon;
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
	
		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

@mixin before-icon ($icon, $position: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0, $fs: 10px, $color: inherit) {
  &:before {
    content: $icon;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    font-size: $fs;
    color: $color;
  }
}

@mixin after-icon ($icon, $position: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0, $fs: 10px, $color: inherit) {
  &:after {
    content: $icon;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    font-size: $fs;
    color: $color;
  }
}

@mixin font-rem($font-size, $font-base: 16) {
	font-size: ($font-size / $font-base)+rem;
}

@mixin opacity ($opacity: .7) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin size ($width, $height, $display: inline-block) {
	display: $display;
	height: $height;
	width: $width;
}

@mixin transition($element: all, $type: ease-in, $time: .2s) {
	transition: $element $type $time;
}

/* property name | duration | timing function | delay */
@mixin transitionfull($name: all, $duration: .2s, $timing: ease-in, $delay:initial ) {
  transition: $name $duration $timing $delay;
}

@mixin grayscale(){
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    -webkit-transition: all linear .25s;
       -moz-transition: all linear .25s;
    	-ms-transition: all linear .25s;
    	 -o-transition: all linear .25s;
    		transition: all linear .25s;

    	&:hover{
    		filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
    		-webkit-filter: grayscale(0%);
    	}
}


@mixin grayscale-on-mouseover(){
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    -webkit-transition: all linear .25s;
       -moz-transition: all linear .25s;
    	-ms-transition: all linear .25s;
    	 -o-transition: all linear .25s;
    		transition: all linear .25s;

}

@mixin image-rouded(){
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
			border-radius: 50%;

}



@mixin side-padding($left: 20, $right: 20){
	padding-left: $left+px;
	padding-right: $right+px;

	@media (max-width: 769px) {
		padding-left: 0;
		padding-right: 0;
	}
}

@mixin no-mr-pd(){
	margin: 0;
	padding: 0;
}

/* Usage @include border-radius(5px); */
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
   -khtml-border-radius: $radius;
       -o-border-radius: $radius;
          border-radius: $radius;
}

/* Usage: @include box-shadow(0,0,20,0,rgba(0,0,0,0.1)); */
@mixin mix-box-shadow($offset-x, $offset-y, $blur-radius, $spread-radius, $color ) {
  -webkit-box-shadow:$offset-x $offset-y $blur-radius $spread-radius $color;
     -moz-box-shadow:$offset-x $offset-y $blur-radius $spread-radius $color;
   -khtml-box-shadow:$offset-x $offset-y $blur-radius $spread-radius $color;
       -o-box-shadow:$offset-x $offset-y $blur-radius $spread-radius $color;
          box-shadow:$offset-x $offset-y $blur-radius $spread-radius $color;
}

/* Usage @include scale(1); */
@mixin scale($params) {
  -webkit-transform: scale($params);
     -moz-transform: scale($params);
   -khtml-transform: scale($params);
       -o-transform: scale($params);
          transform: scale($params);
}

/* Usage @include rotate(135deg); */
@mixin rotate($params) {
  -webkit-transform: rotate($params);
     -moz-transform: rotate($params);
   -khtml-transform: rotate($params);
       -o-transform: rotate($params);
          transform: rotate($params);
}


@mixin blur($blur){
	-webkit-filter: blur($blur);
	  filter: blur($blur);
}


@mixin icomoon-add($content, $color: $blue-base, $font-size: 16, $margin-right: 4, $margin-left: 0){
	font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: $content;
    color: $color;
    font-size: $font-size+px;
    @include font-rem($font-size);
    margin-right: $margin-right+px;
    margin-left: $margin-left+px;
}



/* Placeholders */
%default-list-clean-style{
    list-style: none;
    margin: 0;
    padding: 0;
}

%flex-dir-col{
  display: flex;
  flex-direction: column;
}

%flex-dir-row{
  display: flex;
  flex-direction: row;
}

%flex-samesize{
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
}

%image-responsive{
  display: block;
  height: auto;
  max-width: 100%;
}

%default-box-shadow{
  box-shadow: 0 0 87px rgba(0, 0, 0, 0.16); 
}

@mixin transform-destroy{
  -webkit-transform: translateY(0);
   -moz-transform: translateY(0);
    -ms-transform: translateY(0);
     -o-transform: translateY(0);
        transform: translateY(0);
}

