.dropdown{ 
	position: relative;
	padding-right: 0;
	.iconify{ font-size: 24px; }

	&-menu{
		position: absolute;
	    top: 100%;
	    left: 0;
	    z-index: 1000;
	    min-width: 100%;
	    display: none;
	    padding: 0;
	    margin:0;
	    font-size: 1em;
	    /*color: #212529;*/
	    text-align: left;
	    list-style: none;
	    background-color: $blue-medium;
	    color: $white;
	    background-clip: padding-box;
	    border-radius: .25rem;
	    @extend %default-box-shadow;
		    &:before{
				content:"";
				position: absolute;
				top: -6px;
				right: 6px;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				/*border-bottom: 6px solid $white;*/
			}


	    & > li{
	    	padding: 0;	    	
	    	a{
				text-decoration: none;
				width: 100%;
				justify-content: flex-start;
				line-height: 2.6rem;
				white-space: nowrap;
				color: inherit;

				&:hover,
				&:focus,
				&:active{
					
				}
	    	}
	    	&:first-child{ border-top: none; }
	    }

	    & > .active > a,
	    & > .active > a:focus,
	    & > .active > a:hover{
			/*color: #fff;
			background: #8dc63f;*/
	    }


	}

	&:hover,
	&.open{
		.dropdown-menu{ display: block; }
	}
}