.lv-header{	
	padding-top: 15px;
	padding-bottom: 15px;
	/*background-color: $white;*/
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 2;
	@include media-breakpoint-up(md) {
		padding-bottom: 0;
	}
	
	&-gn{
		background-color: $green-base;
		@include media-breakpoint-up(md) {
        	padding-bottom: 20px;
        }
	}
	&-wt{
		@include media-breakpoint-up(lg) {
			background-color: #fff;
		}
	}

	&-pages{	
		padding-top: 15px;
		padding-bottom: 15px;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 2;
		@include media-breakpoint-up(md) {

		}	
	}
	&-inner{ 
		position: relative;
		display: flex;
		flex-wrap: wrap;
	}

	&-brand{
		@include transition(all .8s ease-in);
		display: flex;
		align-items: center;
		width: auto;
		padding: 0;
		max-width: 190px;
		margin-right: auto;
		margin-left: auto;

		@include media-breakpoint-down(md) {
			margin-left: auto;
			z-index: 1000;
		}
		a{
			@include transition(all .25s ease-in);
			position: relative;
			&:active,
			&:focus,
			&:hover{
				transform: scale(1.03);
				opacity: .9;
			}
		}
	}

		
}



.-header{
	&-inner{ 
		position: relative;
		display: flex;
		flex-wrap: wrap;
	}
	&-brand{
		@include transition(all .8s ease-in);
		display: flex;
		align-items: center;
		width: auto;
		padding: 0;
		max-width: 190px;
		margin: 0 auto 15px;
		

		@include media-breakpoint-up(md) {
			@include make-col(12);
			/*width: 100%; */
		    flex: 0 0 auto;
		    width: auto;
		    max-width: 100%;
		    z-index: 1000;
		    margin-left: 0;
		}

		a{
			@include transition(all .2s ease-in);
			position: relative;
			
			&:active,
			&:focus,
			&:hover{
				transform: scale(1.03);
				opacity: .9;
			}
		}
	}
}

.menu-trigger,
.search-trigger{
	position: absolute;
	width: 24px;
	height: 24px;
	color: $blue-medium;
	font-size: 24px;
	@include font-rem(24);
	display: flex;
	justify-content: center;
	align-items: center;

	&-desktop{
		top: 10px;
 	}
 	&-mobile{
 		top: 28px;
 	}
	
	
	.-btn{
		@include transition(all .2s ease-in);
		position: relative;
		outline: none;
		display: inline-block;
		border: none;
		text-decoration: none;
		background: transparent; 
		color: inherit;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		line-height: 1;
	}
}

.menu-trigger{
 	&-desktop{
 		right: 0px;
 		z-index: 2000;
 		@include media-breakpoint-up(lg) {
 			display: none;
 		}

 		@include media-breakpoint-down(md) {
			visibility: hidden;
		}
 	}
 	&-mobile{
 		right: 17px;
 		@include media-breakpoint-up(md) {
			visibility: hidden;
		}
 	}

 	&-desktop,
 	&-mobile{
 	 	.iconify{
 			-webkit-transform: rotate(180deg);
 			   -moz-transform: rotate(180deg);
 			    -ms-transform: rotate(180deg);
 			     -o-transform: rotate(180deg);
 			        transform: rotate(180deg);
 		}
 	}
 	&.-trigg-white{ color: $white;}
}

.search-trigger{
	&-desktop{
		@include media-breakpoint-up(md) {
			visibility: hidden;
		}
	}
	&-mobile{
		left: 17px;
		@include media-breakpoint-up(md) {
			visibility: hidden;
		}
	}
}

.brand{
	@extend %image-responsive;
	@include media-breakpoint-down(lg) {
		max-width: 115px;
	}

	&-mob{
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	&-desk{
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

}

.header-block{
	@extend %flex-samesize;
}