.-contact{
	&-highlight{
		min-height: 537px;
		width: 100%;
		display: flex;
		overflow: hidden;
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		position: relative;

		@include media-breakpoint-down(lg) {
			min-height: 40vh;
		}

		&:after{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-image: url('../images/hg-about-pe.png');
			background-size: cover;
			background-position: bottom center;
			background-repeat: no-repeat;
		}
	}

	&-first{
		padding-top: 100px;
		padding-bottom: 230px;
		position: relative;

		@include media-breakpoint-down(md) {
			padding-top: 5vh;
			padding-bottom: 10vh;
		}

		&:before{
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background-image: url('../images/big-leaf.png');
			background-position-x: 10vw;
			background-position-y: 0%;
			background-repeat: no-repeat;
		}
		.container{ position: relative;}

		h1{
			font-size: 44px;
			@include font-rem(44);
			color: $blue-medium;
			font-weight: $bold;
			line-height: 1.15;
			margin-bottom: 27px;

			@include media-breakpoint-down(md) {
				font-size: 36px;
				@include font-rem(36);
				margin-bottom: 20px;
			}
		}

		p{
			font-size: 18px;
			@include font-rem(18);
			color: $black;
			font-weight: $regular;
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				font-size: 16px;
				@include font-rem(16);
			}

			a{
				font-size: inherit;
				color: $green-base;
				font-weight: $medium;
				text-decoration: none;
				/*@include media-breakpoint-down(md) {
					font-size: inherit;
				}*/
			}
		}
		
	}

	&-form{
		&-wrap{
			form{
				display: flex;
				flex-direction: column;
				border-radius: 22px;
				background-color: $white;
				padding: 38px;
				box-shadow: 3px 3px 10px rgba(0,0,0,0.16);

				@include media-breakpoint-down(md) {
					padding: 20px 16px;
					box-shadow: 1px 3px 10px rgba(0,0,0,0.26);

				}

				input{
					border: 1px solid $green-base;
					color: $blue-base;
					font-size: 14px;
					@include font-rem(14);
					padding: 16px;
					border-radius: 54px;
					height: 54px;
					margin-bottom: 23px;

					&+input{ margin-left: 0; }
				}

				textarea{
					resize: none;
					border-radius: 22px;
					border: 1px solid $green-base;
					padding: 19px 16px;
					height: 121px;
					width: 100%;
				}

				button[type="submit"], input[type="submit"]{
					margin-top: 27px;
					margin-bottom: 10px;
					border: 1px solid $blue-base;

					&:hover,
					&:focus,
					&:active{
						background-color: $white;
						border-color: $blue-base;
						color: $blue-base;
					}
				}

			}

		}
	}

}
