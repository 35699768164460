.cookiebar {
  padding: 0.6rem 0;
  display: -webkit-box;
  display: flex;
  text-align: center;
  background: #444;
  position: relative;
  font-weight: $bold;

  a{
    color: #f3b823;
    text-decoration: none;
  }

  .hide-cookiebar {
    position: absolute;
    right: 20px;
    top: 3px;

      svg {
        fill: white;
        width: 20px;
        height: 20px;
        position: relative;
        top: 2px;
      }

      .hide-cookiebar-target {
        width: 24px;
        height: 24px;
        display: inline-block;
        border-radius: 50%;
        padding: 4px;
        -webkit-transition: all ease 0.3s;
        transition: all ease 0.3s;
        text-align: center;
      }

      &:hover .hide-cookiebar-target {
        background: rgba(255, 255, 255, 0.9);
        svg {
          fill: #444;
        }
      }
  }

    /*Different style modes*/
    &-bt-fix{
        color: #fff;
        -webkit-transform: translateY(100%);
           -moz-transform: translateY(100%);
            -ms-transform: translateY(100%);
             -o-transform: translateY(100%);
                transform: translateY(100%);
        position: fixed;
        width: 100%;
        height: auto;
        bottom: 0;
        -webkit-transition: all .8s linear;
           -moz-transition: all .8s linear;
            -ms-transition: all .8s linear;
             -o-transition: all .8s linear;
                transition: all .8s linear;

        &.no-cookie-yet{
          -webkit-transform: translateY(0);
             -moz-transform: translateY(0);
              -ms-transform: translateY(0);
               -o-transform: translateY(0);
                  transform: translateY(0);
        }
    }

    &-left-tooltip{
        position: fixed;
        bottom: 20px;
        left: 30px;
        width: 295px;
        padding: 20px 20px 18px 17px;
        background: #fff;
        /*box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 14px;*/
        @extend %default-box-shadow;
        border-radius: 5px;
        margin: 15px auto;
        color: #444;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        z-index: 999;

        -webkit-transform: translateX(-200%);
         -moz-transform: translateX(-200%);
          -ms-transform: translateX(-200%);
           -o-transform: translateX(-200%);
              transform: translateX(-200%);
        -webkit-transition: all .8s linear;
           -moz-transition: all .8s linear;
            -ms-transition: all .8s linear;
             -o-transition: all .8s linear;
                transition: all .8s linear;

        &.no-cookie-yet{
              animation-name: bounceInLeft;
              animation-delay: 1s;
              animation-duration: 1s;
              animation-fill-mode: both;
        }

        .title{
          display: flex;
          font-style: normal;
          font-weight: bold;
          color: #686868;
          line-height: 1.2;
          margin-bottom: 10px;
        }

        p{ 
          font-size: inherit;
          font-weight: inherit;
          margin-bottom: 10px;
        }

        a{
          color: $green-base;
        }

        .hide-cookiebar{
            .hide-cookiebar-target{
              svg{fill: #444;}
            }
            &:hover{
                .hide-cookiebar-target {
                    background: rgba(0, 0, 0, .06);
                    box-shadow: rgba(0, 0, 0, .2) 0px 4px 14px;

                    /*svg {
                      fill: #eee;
                    }*/
                }
            }
        }

        .accept-cookies{
          background-color: $green-base;
          color: #fff;
          border-radius: 30px;
          padding: 4px 18px;
          margin-left: auto;
          font-weight: bold;
          font-size: 14px
        }
    }

    &-bottom-tooltip{
        box-shadow: 0 -1px 3px #eaf0f6;
        border-top: 1px solid #cbd6e2;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        color: $blue-dark;
        margin: 0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        z-index: 2147483647;
        -webkit-transform: translateY(200%);
           -moz-transform: translateY(200%);
            -ms-transform: translateY(200%);
             -o-transform: translateY(200%);
                transform: translateY(200%);
                -webkit-transition: all .4s linear;
                   -moz-transition: all .4s linear;
                    -ms-transition: all .4s linear;
                     -o-transition: all .4s linear;
                        transition: all .4s linear;

        .container-cookiebox{
          width: 100%;
          max-width: 1000px;
          margin: 0 auto;
          padding: 20px;
          @include media-breakpoint-down(md) {
            padding-top: 30px;
          }
        }

        .bottom-act{
          display: flex;
          justify-content: flex-end;
          @include media-breakpoint-down(md) {
            justify-content: center;
          }
        }

        &.no-cookie-yet{
              animation-name: bounceInUp;
              animation-delay: 1s;
              animation-duration: 1s;
              animation-fill-mode: both;
              -webkit-transform: translateY(0);
                 -moz-transform: translateY(0);
                  -ms-transform: translateY(0);
                   -o-transform: translateY(0);
                      transform: translateY(0);
        }

        .title{
          display: flex;
          font-style: normal;
          font-weight: bold;
          color: #686868;
          line-height: 1.2;
          margin-bottom: 10px;
        }

        p{ 
          font-size: inherit;
          font-weight: inherit;
          margin-bottom: 12px;
        }

        a{ color: $green-base; }

        .hide-cookiebar{
            .hide-cookiebar-target{
              padding: 0;
              svg{fill: #444;}
            }
            &:hover{
                .hide-cookiebar-target {
                    background: $green-base;
                    color: $white;
                    box-shadow: rgba(0, 0, 0, .2) 0px 4px 14px;

                    svg {
                      fill: $white;
                    }
                }
            }
        }

        .accept-cookies,
        .refuse-cookies{
          border-radius: 3px;
          padding: 10px 16px;
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          text-decoration: none;
          border: 1px solid transparent;
          margin: 6px;
        }

        .accept-cookies{
          background-color: $green-base;
          border-color: $green-base;
          color: #fff;
          &:hover{
            background-color: $blue-medium;
            border-color: $blue-medium;
          }
        }
        .refuse-cookies{
          background-color: transparent;
          border-color: $green-base;
          color: $green-base;
          &:hover{
            color: $white;
            background-color: $blue-base;
            border-color: $blue-base;
          }
        }
    }    
}

     


/*animations*/
@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}