// Colors
$white: 	            	#FFFEFF;
$white-bg: 	            	#FFFEF8;

$black:						#000000;

$blue-base:                	#010038;
$blue-dark: 				#00101e;
$blue-medium: 				#1E2D61;

$green-base:				#6BDD9C;

$orange-base:               #E35207;
$orange-dark:				#C84800;


$foreground-opacity:	rgba(34, 34, 34, .5);
$foreground-search-opacity:	rgba(34, 34, 34, .8); 

// Typekit Fonts
$montserrat-webfont: 'Montserrat', sans-serif;

// Fonts
$base-font-family: $montserrat-webfont;

// Weights
/*$light:		300;*/
$regular:		400;
$medium:		500;
$semibold:		600;
$bold:			700;
$extrabold:		800;


//Background
$default-bg-color: $white;