.-socials{
	color: $green-base;
	padding: 0;
	margin: 0;
	font-size: 17px;
	@include font-rem(17);
	ul{
		@extend %default-list-clean-style;
		@extend %flex-dir-row;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	
	}
	
	li{
		margin-top: 24px;
		margin-bottom: 10px;
		/*margin-right: 14px;
		&:first-child{margin-left: 0;}*/
	}

	a{
		width: 34px;
		height: 34px;
		color: $green-base;
		border: 2px solid $green-base;
		border-radius: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}	
}