.-title{
    font-size: 40px;
    @include font-rem(40);
    font-weight: $bold;
    line-height: 1.2;
    color: $blue-medium;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      @include font-rem(24);
    }

}

.-subtitle{
 /* font-size: 18px;
  @include font-rem(18);
  font-weight: $regular;

      @include media-breakpoint-down(sm) {
        font-size: 15px;
        @include font-rem(15);
      }*/


}

h1, h2, h3, h4, h5, h6{
  /*position: relative;
  color: $blue-base;*/
  margin: 0 0 1.4rem;
}

h1{
  color: $blue-medium;
  font-weight: $bold;
  font-size: 44px;
  @include font-rem(44);
  line-height: 1.15;
  /*
  padding-bottom: 8px;
  @include media-breakpoint-down(sm) {
    font-size: 26px;
    @include font-rem(26);
  }*/
}

h2{ 
  color: $blue-medium;
  font-weight: $bold;
  font-size: 38px;
  @include font-rem(38);
  /*line-height: 1.54;*/
  line-height: 1.2;
  margin-bottom: 15px;
}



h3{ 
  color: $blue-medium;
  font-weight: $bold;
  font-size: 20px;
  @include font-rem(20);
  line-height: 1.5;
}

h5{
    font-weight: normal;
    text-decoration: none;
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-size: 16px;
    text-transform: none;
    margin: 0 0 1.4rem;
}

