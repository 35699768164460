.-btn{
	@include transition;
	position: relative;
	outline: none;
	display: inline-block;
	border: none;
	text-decoration: none;
	/* background: transparent; */
	color: inherit;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	line-height: 1;

	&:hover,
	&:focus,
	&:active{
		text-decoration: none;
		outline: none;
	}

	&-transparent{ background-color: transparent; }

	&-primary{
		@include transition();
		display: inline-block;
        background-color: $blue-base;
        color: $white;
        border: 1px solid $blue-base;
        font-weight: $medium;
        font-size: 12px;
        @include font-rem(12);
        border-radius: 30px;
        padding: 17px 32px;
        text-decoration: none;
        
        @include media-breakpoint-up(md) {
            &:hover{
                color: $blue-base;
                background-color: $white;
            }
        }
        &:valid{ color: $white;}
		
	}

	&-secondary{
		@include transition;
		background-color: $orange-base;
		color: $white;
		padding: 17px 34px;
		text-transform: uppercase;
		font-size: 14px;
		@include font-rem(14);
		font-weight: $semibold;
		text-decoration: none;
		border-radius: 30px;
		margin-top: 10px;
		display: inline-block;

		&:active,
		&:focus,
		&:hover{ 
			background-color: $orange-dark;
			color: $white;
		}
	}

	&-terciary{
		@include transition;
		background-color: transparent;
		border: 2px solid $green-base;
		color: $green-base;
		padding: 17px 34px;
		text-transform: uppercase;
		font-size: 12px;
		@include font-rem(12);
		font-weight: $medium;
		text-decoration: none;
		border-radius: 30px;

		&:active,
		&:focus,
		&:hover{ 
			background-color: $blue-base;
  			border-color: $blue-base;
			color: $white;
		}
	}	

	&-read{
		@include transition();
		background-color: transparent;
		color: $blue-base;
		padding: 0;
		font-size: 12px;
		@include font-rem(12);
		font-weight: $bold;
		text-decoration: none;
		border: none;
		@include media-breakpoint-up(md) {
          font-size: 16px;
          @include font-rem(16);
        }   

		&:active,
		&:focus,
		&:hover{
		}

		.iconify{ 
			margin-left: 10px;
			font-size: 24px;
			@include font-rem(24);
			color: $green-base;
		}
	}


	&-link{ }

	&-flex-bottom-start{
		margin-top: auto;
		align-self: flex-start;
	}

	&-disabled{
		opacity: .5;
		cursor: default;
	}

	&-white{
		@include transition;
		position: relative;
		outline: none;
		display: inline-block;
		border: none;
		text-decoration: none;
		color: $blue-medium;
		background-color: $white;
		font-weight: $bold;
		cursor: pointer;
		border-radius: 41px;
		-webkit-appearance: none;
		-moz-appearance: none;
		text-align: center;
		line-height: 1.38;
		padding: 17px 50px;
		box-shadow: 2px 2px 10px rgba(0,0,0,.16);

		&:hover,
		&:focus,
		&:active{
		  background-color: $blue-medium;
		  color: $white;
		  text-decoration: none;
		  outline: none;
		  font-weight: $medium;
		}

		b, strong{
			color: $green-base;
		}
	}
	
}

.scroll-down{
	@include transition;
	position: relative;
	outline: none;
	display: inline-block;
	border: none;
	text-decoration: none;
	background: transparent; 
	color: inherit;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	line-height: 1;
	outline: none;
	&:hover,
	&:focus,
	&:focus{
		outline: none;
	}
}


#customize-controls,
#customize-controls-rel{
	list-style: none;
	padding: 0;
	margin: 0 0 25px;
	display: flex;
	li{
		@include transition;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		outline: none;
		border: none;
		text-decoration: none;
		background: #254CCE; 
		color: #fff;
		font-size: 24px;
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		border-radius: 40px;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		line-height: 1;
		margin-right: 16px; 

		&:hover,
		&:focus,
		&:active{
			text-decoration: none;
			outline: none;
		}
	}
}

#customize-controls-rel{
	justify-content: center;
	margin-top: 40px;
	li{margin: 0 8px;}
}