.custom-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0 0 0 20px;
    left: auto;
    font-size: 14px;
    @include font-rem(14);
    line-height: 21px;
  }

  // Box.
  & + label:before,
  & + label:after{
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    margin-right: 9px;
    display: inline-block;
    vertical-align: text-top;
    width: 12px;
    height: 12px;
    border: 1px solid $blue-base;
    background-color: $white;
  }

  & + label:after{
    background-color: $white;
    border: none;
    width: 8px;
    height:8px;
    left: 2px;
    top: 5px;
  }

  // Box hover
  &:hover + label:after {
    background: $blue-medium;
  }
  
  // Box focus
  &:focus + label:before {
    /*box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);*/
  }

  // Box checked
  &:checked + label:after {
    background-color: $green-base;
  }
  
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

}