.-subscribe{
	background: $green-base;
	color: $blue-dark;
	padding-top: 54px;
	padding-bottom: 60px;
	&-wrap{
		padding-right: 28px;
		padding-left: 28px;

		form{
			flex-wrap: wrap;
			.wpcf7-form-control-wrap{
				@include make-col(6);
				@include media-breakpoint-down(md) {
					@include make-col(12);
				}
			}
			.inline-submit{
				flex: 0 0 auto;
				width: calc(50% - 25px);
				@include media-breakpoint-down(md) {
					@include make-col(12);
				}
			}
		}
	}
}