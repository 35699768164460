.-jobs{
	&-highlight{
		min-height: 537px;
		width: 100%;
		display: flex;
		overflow: hidden;
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		position: relative;

		@include media-breakpoint-down(md) {
			min-height: 40vh;
		}

		&:after{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-image: url('../images/hg-about-pe.png');
			background-size: cover;
			background-position: bottom center;
			background-repeat: no-repeat;
		}
	}

	&-first{
		padding-top: 102px;
		padding-bottom: 130px;
		position: relative;

		@include media-breakpoint-down(lg) {
			padding-top: 7vh;
			padding-bottom: 10vh;
		}

		@include media-breakpoint-down(md) {
			padding-top: 5vh;
			padding-bottom: 10vh;
		}

		&:before{
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background-image: url('../images/big-leaf.png');
			background-position-x: 70%;
			background-position-y: top;
			background-repeat: no-repeat;
		}	
		.container{ position: relative;}

		h1{
			font-size: 44px;
			@include font-rem(44);
			color: $blue-medium;
			font-weight: $bold;
			line-height: 1.15;

			@include media-breakpoint-down(md) {
				font-size: 36px;
				@include font-rem(36);
				margin-bottom: 20px;
			}
		}

		h3{
			font-size: 30px;
			@include font-rem(30);
			color: $blue-medium;
			font-weight: $extrabold;
			margin-bottom: 0;
		}

		h4{
			font-size: 22px;
			@include font-rem(22);
			font-weight: $medium;
			color: $green-base;
			line-height: 1.95;
			& + p{
				margin-top: 40px;
			}
		}

		p{
			font-size: 18px;
			@include font-rem(18);
			color: $black;
			font-weight: $regular;

			@include media-breakpoint-down(md) {
				font-size: 14px;
				@include font-rem(14);
			}
		}

		&-content{
			h3{
				&:first-of-type{
					/*padding-top: calc(156px + 128px);*/
					margin-top: 15vh;
					margin-bottom: 40px;
					@include media-breakpoint-down(lg) {
						margin-top: 0;
						margin-bottom: 20px;
					}
					@include media-breakpoint-down(md) {
						font-size: 20px;
					}

				}
			}
		}
	}	

	&-list{
		list-style: none;
		margin: 0;
		padding: 0;
		margin-top: 30px;
		li{
			padding: 28px 36px 17px;
			border-radius: 19px;
			box-shadow: 2px 2px 10px rgba(0,0,0,0.16);
			margin-bottom: 50px;
			background-color: $white;

			@include media-breakpoint-down(md) {
				padding: 20px 14px;
				box-shadow: 2px 2px 10px rgba(0,0,0,0.26);
				margin-bottom: 30px;
			}

			.-btn-primary{
				margin-top: 0;
				display: block;
				text-align: center;
			}
		}
	}

	&-title{
		font-size: 18px;
		@include font-rem(18);
		font-weight: $bold;
		line-height: 1.38;
		margin-bottom: 10px;

		@include media-breakpoint-down(md) {
			font-size: 16px;
			@include font-rem(16);
			font-weight: $bold;
			line-height: 1.38;
			margin-bottom: 10px;
		}
	}

	&-desc{
		line-height: 1.38;
		margin-bottom: 20px;
		font-size: 16px;
		@include font-rem(16);
		p{}
	}

}
