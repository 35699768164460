.lv-footer{
	position: relative;
	background-color: $blue-dark;
	padding-top: 20px;
	padding-bottom: 20px;
	overflow: hidden;
	color: $white;
	&-alt{
		background-color: $green-base;
		padding-top: 27px;
		padding-bottom: 36px;
	}
	p{ margin-bottom: 0;
		@include media-breakpoint-down(sm) { 
			text-align: center;
			margin-bottom: 1rem;
		}
	}
}

.-footer{
	$about-max: 400px;
	$newsletter-max: 270px;

	&-title{ 
		color: $white;
		margin-bottom: 27px;
		font-size: 16px;
		@include font-rem(16);
		/*font-weight: $semibold;*/

	}

	&-inner{
		/*padding-bottom: 20px;*/
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		&-alt{
			padding-bottom: 0;
		}

		&>*{
			flex: 1;
			width: auto;
		}
	}

	&-about{
		@include make-col(12);
		margin-bottom: 24px;

		@include media-breakpoint-up(md) {
			max-width: $about-max;
			margin-bottom: 0;
		}

		@include media-breakpoint-down(md) {
			display: flex;
		    flex-wrap: wrap;
		    align-items: center;
		}

		@include media-breakpoint-down(sm) {
			padding-top: 140px;
    		flex-direction: column-reverse;
		}
	}

	&-description{
		margin-bottom: 0;
		max-width: 50%;
		@include media-breakpoint-down(md) {
			flex: 0 0 auto;
			width: 50%;
			margin-bottom: 112px
		}
		@include media-breakpoint-down(sm) {
			margin: auto;
    		width: 100%;
			font-size: 14px;
			@include font-rem(14);
		}

		p{
			margin-bottom: 14px;
			line-height: 1.7;
			@include media-breakpoint-down(md) {
				margin-bottom: 0;
			}
		}		
	}

	&-socials{
		color: $white;
		padding: 0;
		margin: 0;
		@extend %default-list-clean-style;
		@extend %flex-dir-row;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		font-size: 17px;
		@include font-rem(17);
		
		li{
			margin-bottom: 10px;
			/*margin-right: 14px;*/
			margin-right: 5px;
			&:first-child{margin-left: 0;}
		}

		a{
			@include transition();
			width: 34px;
			height: 34px;
			color: $white;
			border: 2px solid $white;
			border-radius: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover{
				background-color: $white;
				color: $blue-dark;
			}
		}
	}

	&-brand{
		display: flex;
		align-items: center;
		width: auto;
		max-width: 180px;
		margin-bottom: 28px;
		@include media-breakpoint-down(md) {
			flex: 0 0 auto;
		    width: 50%;
		    margin-bottom: 112px;
		}

		@include media-breakpoint-down(sm) {
			margin: auto;
			width: 100%;
			margin-left: 0;
		}

		&.soon{
			@include media-breakpoint-down(md) {
		    	margin-bottom: 152px;
		    }
		    @include media-breakpoint-down(sm) {
		    	margin-bottom: 0;
		    	margin-left: 0;
		    }
	    }

		&-alt{
			margin-bottom: 0;
		}

		a{
			@include transition();
			&:active,
			&:focus,
			&:hover{
				transform: scale(1.03);
				opacity: .9;
			}
		}
	}

	&-navs{
		display: flex;
		flex: 1;
		width: auto;
	}

	&-navbar{
		flex: 1;

		@include media-breakpoint-only(md) {
			@include make-col(4);
		}

		@include media-breakpoint-down(sm) {	
			@include make-col(12);
		}
		

		&--nav,
		& ul[role="menu"]{
			color: $white;
			padding: 0;
			margin: 0;
			@extend %default-list-clean-style;
			@extend %flex-dir-col;

			li{
				list-style: none;
				padding: 0;
				font-size: 16px;
				@include font-rem(16);
				font-weight: $regular;
				line-height: 1.2;
				margin-bottom: 15px;

				a{
					color: inherit;
					text-decoration: none;
					position: relative;
					@include transition();

					.iconify{ margin-right: 16px; }
				}

				&.current-menu-item,
				&:hover {
					opacity: .8;
					text-decoration: underline;
				}

				&.active{
					/*color: $gray-base;*/
				}
			}
		}

		/*&+&{margin-left: 85px;}*/
	}

	&-download,
	&-download-alt{
		flex: 0 0 auto;
		/*margin-top: 30px;*/
		@include media-breakpoint-down(md) {
			position: absolute;
			display: flex;
			justify-content: space-around;
			right: 0;
			width: 100%;
			top: 96px;
			margin-top: 0;
			flex-wrap: wrap;
		}
		@include media-breakpoint-down(sm) {
			top: 0;
			.-footer-title{
				width: 100%;
				text-align: center;
			}
		}
		a{ 
			display: flex;
			align-items: center;
			padding: 15px 20px;
			justify-content: center;
			@include media-breakpoint-down(md) {
				padding: 12px 20px;
				display: flex;
				align-items: center;
			}

			.iconify{
				font-size: 28px;
				@include font-rem(28);
				margin-right: 8px;
				@include media-breakpoint-down(md) {
					font-size: 26px;
					@include font-rem(26);
				}
			}
		}
		a+a{
			margin-top: 15px;
			@include media-breakpoint-down(md) {
				margin-top: 0
			}
		}
	}

	&-download-alt{
		
	}

	&-out{
		background-color: $blue-dark;
		color: $white;
		padding: 0 0 38px;

		&-wrapper{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;

			@include media-breakpoint-down(sm) {
				justify-content: flex-start;
			}

			&>*{
				flex: 0 0 auto;
				width: auto;
			}

			a{ 
				text-decoration: none;
				color: inherit;
			}
		}
	}

	&-copyright{
		font-size: 16px;
		@include font-rem(16);
		color: $white;
		text-decoration: none;
			
		@include media-breakpoint-down(sm) {				
			@include make-col(12);
			font-size: 12px;
			@include font-rem(12);
			text-align: center;
		}
	}

	/*&-signature{
		@include media-breakpoint-down(md) {
			font-size: 12px;
			@include font-rem(12);
			margin: 0 auto;
			color: $purple-base;
		}
	}*/


}


