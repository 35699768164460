form{
    @include media-breakpoint-up(md) {
        display: flex;
    }
    label{
        color: $blue-medium;
        font-size: 14px;
        @include font-rem(14);
        font-weight: $regular;

        &+input,
        &+textarea,
        &+select{ 
            margin-top: 20px;
                @include media-breakpoint-down(sm) {
                }
        }
    }

    /*span,
    .wpcf7-form-control-wrap{ flex: 1; display: flex;}*/

    .wpcf7-form-control-wrap+.inline-submit{
        @include media-breakpoint-up(md) {
            /*flex: 1;*/
            font-weight: $medium;
            margin-left: 25px;
        }
    }

    input{ 
        background-color: $white;
        font-size: 14px;
        @include font-rem(14);
        font-weight: $regular;
        border-radius: 20px;
        height: 40px;
        border: none;
        box-sizing: border-box;
        outline: none;
        box-shadow: none;
        color: $blue-medium;
        width: 100%;
        padding: 10px 14px;
        margin-bottom: 12px;

        @include media-breakpoint-up(md) {
            font-weight: $medium;
            &+input{ margin-left: 25px;}
        }

        &[type="submit"]{
            @include transition();
            background-color: $blue-base;
            color: $white;
            border: 1px solid $blue-base;
            font-weight: $medium;

            @include media-breakpoint-up(md) {
                &:hover{
                    color: $blue-base;
                    background-color: $white;
                }
            }
            
            &:valid{ color: $white;}
        }

        &:hover{
            @include media-breakpoint-up(md) {
                &::placeholder {
                    font-weight: $regular;
                    color: $blue-base;
                }
            }
        }

        &:focus,
        &:valid{
            color: $blue-base;
            &::placeholder {
                color: $blue-base;
            }
        }

        &:invalid{
            color: #555554;
        }

        &::placeholder {
            color: #555554;
            font-weight: $regular;
        }
    } 
    
    textarea{
        border-radius: 20px;
    } 
    
    select{
        outline: none;
        width: 100%;
        border: none;
        padding: 9px 17px;
        color: $blue-medium;
        font-weight: $medium;
        border-radius: 30px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;       /* Remove default arrow */       
        background: #fff url('https://api.iconify.design/bx/bxs-down-arrow.svg?color=%231E2D61&width=16&height=50') no-repeat 95% center / contain;
    }

    

}

.wpcf7{
    form{
        &.failed{
            .wpcf7-response-output{
                border-color: #A7080D;
                background-color: #A7080D;
                color: $white;
            }
        }
        &.aborted{
          .wpcf7-response-output{}
        }
        .wpcf7-response-output{
            @include make-col(12);
            margin: 10px 0;
            border-radius: 16px;
        }
    }
}