$menu-left-spacing: 100px;
$active-color: $blue-base;

.pd-l-menu{ 
	padding-left: $menu-left-spacing;
	@include media-breakpoint-down(sm) {
		padding-left: 40px;
	}
}
.mg-l-menu{ 
	margin-left: $menu-left-spacing;
	@include media-breakpoint-down(sm) {
		margin-left: 40px;
	}
}

.-offcanvas{
	@include transition();
	position: fixed;
	/*z-index: 2100;*/
	z-index: 2010;
	top: 0;
	right: 0;
	background-color: $white-bg;
	color: $black;
	width: 83.64%;
	max-width: 358px;
	height: 100%;
	padding: 10px 20px 30px 20px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	-webkit-transform: translate3d(100vw, 0, 0);
	   -moz-transform: translate3d(100vw, 0, 0);
	    -ms-transform: translate3d(100vw, 0, 0);
	     -o-transform: translate3d(100vw, 0, 0);
	        transform: translate3d(100vw, 0, 0);


	&-footer{ 
		margin-top: auto;
		display: flex;
		flex-wrap: wrap;

		
		a{
			&:hover,
			&:focus,
			&:active{
				color: $white;
				background-color: $green-base;
				border: 1px solid $green-base;
				font-weight: $bold;

				span{
					background-color: $green-base;
					border-color: $green-base;
				}

			}

			@include media-breakpoint-down(sm) {
				margin-top: 5px;
				margin-bottom: 5px;
			}
		}
		.-btn-primary{
			@include media-breakpoint-down(lg) {
				padding: 12px 24px;
				&+.-btn-primary{ margin-left: 5px;}
			}
			@include media-breakpoint-down(sm) {
				font-size: 10px;
				padding: 10px;
				margin: 0;
				.iconify{ font-size: 18px;}

			}
		}

		.-socials{
			li{
				@include media-breakpoint-down(lg) {
					margin-top: 10px;
				}
				&+li{
					@include media-breakpoint-down(lg) {
						margin-left: 4px;
					}
				}
			}
		}
	}

	&-languages{
		display: flex;
		align-items: center;
		font-size: 13px;
		margin-left: 20px;
		@include media-breakpoint-down(lg) {
			margin-left: 0;
			flex: 0 0 auto;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
		}
		&-label{ 
			font-size: 26px;
			display: flex;
			margin: auto;
			color: $blue-medium;
		}
		.dropdown{
			@include media-breakpoint-down(lg) {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				margin-left: 0;
			}
			&-menu{
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				min-width: 94px;
				-webkit-transform: translate3d(-35px, 0, 0);
				   -moz-transform: translate3d(-35px, 0, 0);
				    -ms-transform: translate3d(-35px, 0, 0);
				     -o-transform: translate3d(-35px, 0, 0);
				        transform: translate3d(-35px, 0, 0);

				        &:before{
				        	content: '';
				        	left: calc(50% - 8px);
				        	top: -8px;
							width: 0px;
							height: 0px;
							border-left: 8px solid transparent;
							border-right: 8px solid transparent;
							border-bottom: 8px solid $blue-medium;
							@include media-breakpoint-down(lg) {
								visibility: hidden;
							}
				        }

				        @include media-breakpoint-down(lg) {
				        	box-shadow: none;
				        	background-color: transparent;
				        	color: $blue-medium;
				        	position: relative;
				        	display: flex;
				        	flex-direction: row;
				        	-webkit-transform: translate3d(0, 0, 0);
							   -moz-transform: translate3d(0, 0, 0);
							    -ms-transform: translate3d(0, 0, 0);
							     -o-transform: translate3d(0, 0, 0);
							        transform: translate3d(0, 0, 0);
				        }
				li{
					display: flex;
					border-radius: 4px; 
					overflow: hidden;
					a{ 
						display: flex;
						padding: 10px;
						line-height: 1.2;
						&:hover{
							background-color: transparent;
							color: $green-base;
							border: none;
						}
					}
				}
			}

			&:hover{
				.dropdown-menu{
					display: flex;
					-webkit-transform: translate3d(-35px, 8px, 0);
					   -moz-transform: translate3d(-35px, 8px, 0);
					    -ms-transform: translate3d(-35px, 8px, 0);
					     -o-transform: translate3d(-35px, 8px, 0);
					        transform: translate3d(-35px, 8px, 0);
				}
			}
		}

	}

	&-close{
		@include transition();
		position: absolute;
		top: 25px;
		right: 30px;
		width: 28px;
		height: 28px;
		font-size: 30px;
		outline: none;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		background-color: transparent;

			&:hover,
			&:focus,
			&:active{
				opacity: .8;
				background-color: transparent;
			}

	        &-x{
	        	position: relative;
	        	width: 100%;
	        	height: 100%;
	        	&:before,
	        	&:after{
	        		content: '';
	        		position: absolute;
	        		left: 50%;
	        		width: 2px;
	        		height: 100%;
	        		background-color: $green-base;
	        	}
	        	&:before{
	        		-webkit-transform: rotate(45deg);
	        		   -moz-transform: rotate(45deg);
	        		    -ms-transform: rotate(45deg);
	        		     -o-transform: rotate(45deg);
	        		        transform: rotate(45deg);
	        	}
	        	&:after{
	        		-webkit-transform: rotate(-45deg);
	        		   -moz-transform: rotate(-45deg);
	        		    -ms-transform: rotate(-45deg);
	        		     -o-transform: rotate(-45deg);
	        		        transform: rotate(-45deg);
	        	}
	        }
		

			@at-root {
		    	.menu-active & {
					-webkit-transform: translateX(0);
					   -moz-transform: translateX(0);
					    -ms-transform: translateX(0);
					     -o-transform: translateX(0);
					        transform: translateX(0);
				    }
			}
		
		

			[class*="icon"]{
				color: $black;
				-webkit-transition: -webkit-transform .25s, opacity .25s;
				   -moz-transition: -moz-transform .25s, opacity .25s;
				    -ms-transition: -ms-transform .25s, opacity .25s;
				     -o-transition: -0-transform .25s, opacity .25s;
				        transition: transform .25s, opacity .25s;
			}
	}

	&-brand{
		position: absolute;
		top: 24px;
		left: 38px;
		width: 50%;
		max-width: 95px;
		@include media-breakpoint-down(lg) {
			max-width: 60px;
			.brand{ max-width: 100%;}
		}
		a{ 
			display: flex;
			@include media-breakpoint-down(lg) {
				align-items: center;
			}
		}


	}

	&-nav{	
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		padding-top: 10px;
		padding-right: 6px;
		padding-bottom: 10px;
		padding-left: 6px;
		height: auto;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		/*margin-top: 155px;*/
		/*z-index: 2;*/
		@include media-breakpoint-down(lg) {
			margin-top: 60px;
		}
    	
		li{
			display: flex;
			height: auto;
			margin: 0 auto;
			/*margin-bottom: 0;*/
			width: 100%;
			font-size: 18px;
			@include font-rem(18);
			font-weight: $regular;
			
			a{
				@include transition(all .1s ease-in);
				display: flex;
				padding: 8px 12px;
				color: inherit;
				height: auto;
				width: 100%;
				justify-content: space-between;
				text-decoration: none;

					&:after,
					&:before{
						@include media-breakpoint-down(md) {
							display: none;
						}
					}

				&:hover{}
			}

			&.-btn{
				margin: 40px auto;
				a{
					border-radius: 0;
					padding: 20px;
					text-align: left;
					justify-content: flex-start;
				}
			}

			&-active{
				a{}
			}			
		}
	}

	.dropdown{
			flex-direction: column;
			margin: 0 auto;
			position: relative;
			&-menu{
				position: absolute;
				display: none;
			}

			&.open{
				.dropdown-menu{
					position: relative;
					margin: 0;
					top: 0;
					padding: 0;
					background-color: $blue-base;
					li{border-top: none;}
				}
			}
		
	}

	&-active{
		box-shadow: 0 0 10px rgba(0,0,0,.3);
		-webkit-transform: translate3d(0,0,0);
		   -moz-transform: translate3d(0,0,0);
		    -ms-transform: translate3d(0,0,0);
		     -o-transform: translate3d(0,0,0);
		        transform: translate3d(0,0,0);

		        overflow-y: auto;
	}

	&::-webkit-scrollbar {
	    width: 12px;
	}
	 
	&::-webkit-scrollbar-track {
	    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
	    border-radius: 10px;
	    background-color: $blue-medium;
	}
	 
	&::-webkit-scrollbar-thumb {
	    border-radius: 10px;
	    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
	    background-color: $blue-base;
	}

	&-outter{
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $foreground-opacity;
		display: none;
		opacity: 0;
		-webkit-transition: opacity 25s;
		-moz-transition: opacity 25s;
		-ms-transition: opacity 25s;
		 -o-transition: opacity 25s;
		    transition: opacity 25s;
			

		.menu-active &{ 
			@include media-breakpoint-down(lg) {
			    z-index: 1000;
			    display: block;
			    opacity: 1;
			}
		}

	}

	.menu-active &{ 
		-webkit-transform: translate3d(0, 0, 0);
		   -moz-transform: translate3d(0, 0, 0);
		    -ms-transform: translate3d(0, 0, 0);
		     -o-transform: translate3d(0, 0, 0);
		        transform: translate3d(0, 0, 0);
	}

    /*New menu*/
    @include media-breakpoint-up(lg) {
    	position: relative;
    	width: auto;
    	max-width: initial;
    	height: auto;
    	flex-direction: row;
    	background-color: transparent;
    	padding: 0;
    	flex: 0 0 auto;
    	-webkit-transform: none;
    	   -moz-transform: none;
    	    -ms-transform: none;
    	     -o-transform: none;
    	        transform: none;
    	        &-header{
    	        	display: none;
    	        }
    	        &-nav{
    	        	margin: 0;
    	        	flex-direction: row;
    	        	align-items: center;
    	        	/*flex: 1;*/
    	        	padding: 0;
    	        	display: flex;
        			align-items: center;
        			flex-wrap: nowrap;

    	        	li{
    	        		text-align: center;
    	        		a{
    	        			justify-content: center;
    	        			white-space: nowrap;
    	        			color: $blue-medium;
    	        			border-bottom: 1px solid transparent;
    	        			&:hover,
    	        			&:focus,
    	        			&:active{
    	        				font-weight: $bold;
    	        				border-bottom-color: #6cdd9d;
    	        			}
    	        		}

    	        		&.active,
    	        		&.current_page_parent{
    	        			a{
    	        				font-weight: $bold;
    	        				border-bottom: 1px solid #6cdd9d;
    	        			}
    	        		}
    	        	}
    	        }

    	        &-footer{
    	        	margin: auto;
    	        	flex-direction: row;

    	        	.-btn-primary{ display: none; }

    	        	.-socials{
    	        		margin-left: 30px;

    	        		li{ 
    	        			margin-top: 0;
    	        			margin-bottom: 0;
    	        			margin-left: 5px;
    	        		}
    	        		a{
    	        			@include transition(all .25s ease-in);
    	        			margin-top: 0;
    	        			margin-bottom: 0;
    	        		}
    	        	}
    	        }
    }

   

    /*@include media-breakpoint-down(md) {
    	&-nav{
    		li{
    			a{
        			&:before,
        			&:after{ display: none; }
        		}
    	    }
    	}
    }*/





}
.menu-active{
	.offcanvas-outter{

	}
}



	/*&-navbar{
		display: flex;
		@include make-col(8);
		margin-left: auto;

		&--nav{
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			width: 100%;
			height: 100%;
			@extend %default-list-clean-style;
			@extend %flex-dir-row;

			@include media-breakpoint-down(lg) {}

			li{
				list-style: none;
				padding: 0;
				font-size: 16px;
				@include font-rem(16);
				font-weight: $medium;
				text-align: center;
				line-height: 1.2;
				display: flex;
				align-items: center;
				height: 100%;
				margin-right: 26px;
				margin-left: 26px;

				&.dropdown > a{ 
					@include media-breakpoint-up(md) {
						padding-right: 0;
					}
				}

				a{
					color: inherit;
					display: flex;
					align-items: center;
					text-decoration: none;
					position: relative;
					white-space: nowrap;
					text-decoration: none;
					@include transition();

					@include media-breakpoint-down(lg) {
						padding-right: 10px;
						padding-left: 10px;
					}

				}
				&.active{ 
					a{text-decoration: underline;}
				}

				&.active,
				&.current-menu-item,
				&:hover{
					a{
						opacity: .8;
						@at-root {
							.bau-header-fixed &{
								text-decoration: none;
								color: $blue-base;
								opacity: 1;
							}
						}
						
					}

					.dropdown-menu a{
						opacity: 1;
						color: $gray-nav-base;
						&.active,
						&.current-menu-item,
						&:hover{ color: $blue-base; }

					}
				}
				&:first-child{ margin-left: 0;}

				&:last-child{ margin-right: 0;}
			}

			.-featured{
				a{ 
					@at-root {
						.bau-header-fixed &{
							
						}
					}
					&:active,
					&:focus,
					&:hover{
						
					}
				}


			}

			.-btn{
				a{ 

					border-radius: 20px;
					
					color: $white;
					padding: 11px 16px;
					font-size: 14px;
					@include font-rem(14);
					&:active,
					&:focus,
					&:hover{
						opacity: 1;
						background-color: $white;
						color: $purple-base;
					}

					@at-root {
						.bau-header-fixed &{
							background-color: $blue-base;
							border: 1px solid $blue-base;
							&:active,
							&:focus,
							&:hover{
								background-color: $white;
								color: $blue-base;
							}
						}
					}

						@include media-breakpoint-down(lg) {
							margin-left: inherit;
							flex: 0 0 auto;
							width: 100%;
							justify-content: center;
							padding: 5px 10px;
						}
				}

			}			
		}
	}*/