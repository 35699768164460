.-about{
	&-highlight{
		min-height: 537px;
		width: 100%;
		display: flex;
		overflow: hidden;
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		position: relative;

		@include media-breakpoint-down(lg) {
			min-height: 40vh;
		}

		@include media-breakpoint-down(md) {
			min-height: 45vh;
		}

		&:after{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-image: url('../images/hg-about-pe.png');
			background-size: cover;
			background-position: bottom center;
			background-repeat: no-repeat;
		}		
	}

	/*First*/
	&-first{
		padding-top: 34px;
		padding-bottom: 130px;

		@include media-breakpoint-down(lg) {
			padding-bottom: 30px;
		}

		@include media-breakpoint-down(md) {
			padding-top: 5vh;
			padding-bottom: 5vh;
		}

		h1{
			font-size: 44px;
			color: $blue-medium;
			font-weight: $bold;
			line-height: 1.15;
			margin-top: 68px;

			@include media-breakpoint-down(lg) {
				margin-top: 10px;
				margin-bottom: 30px;
			}

			@include media-breakpoint-down(md) {
				font-size: 36px;
				margin-bottom: 20px;
			}
		}

		h3{
			font-size: 30px;
			color: $blue-medium;
			font-weight: $bold;
			margin-bottom: 0;

			@include media-breakpoint-down(md) {
				font-size: 22px;
			}

			& + p{ margin-top: 40px;}
		}

		h4{
			font-size: 22px;
			font-weight: $medium;
			color: $green-base;
			line-height: 1.95;

			@include media-breakpoint-down(md) {
				font-size: 18px;
				line-height: 1.4;
			}

			& + p{
				margin-top: 40px;

				@include media-breakpoint-down(md) {
					margin-top: 30px;
				}
			}	
		}

		p{
			font-size: 18px;
			@include font-rem(18);
			color: #000;
			font-weight: $regular;
			margin-bottom: 40px;

			@include media-breakpoint-down(md) {
				font-size: 16px;
				@include font-rem(16);
				font-weight: $medium;
				margin-bottom: 20px;
			}
		}

		li{
			font-size: 16px;
			color: #000;
			font-weight: $regular;
			margin-bottom: .5rem;

			@include media-breakpoint-down(md) {
				font-size: 14px;
			}
		}

		img{ 
			margin-bottom: 51px;

			@include media-breakpoint-down(md) {
				margin-bottom: 30px;
			}
		}

		&.-default{
			p{ margin-bottom: 1em;}
		}
	}

	/*Second*/
	&-second{
		background-color: rgba(102, 221, 156, .2);
		padding-top: 144px;
		padding-bottom: 115px;

		@include media-breakpoint-down(lg) {
			padding-top: 10vh;
			padding-bottom: 10vh;
		}

		&-title{ 
			display: block;
			text-align: center;
			margin-bottom: 157px;

			@include media-breakpoint-down(lg) {
				margin-bottom: 30px
			}
		}

		&-slider{
			display: flex;
			flex-direction: row;
			overflow-x: auto;
			padding-left: 5vw;

			@include media-breakpoint-down(lg) {
				padding-bottom: 30px;
			}

			@include media-breakpoint-up(md) {
				&::-webkit-scrollbar {
					/*width: 12px;*/
					display: none;
				}

				&::-webkit-scrollbar-track {
					display: none;
				    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
				    border-radius: 10px;
				    background-color: $blue-dark;*/
				}

				&::-webkit-scrollbar-thumb {
					display: none;
				    /*border-radius: 10px;
				    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
				    background-color: $blue-base;*/
				}
			}
		}

		&-sl-item{
			position: relative;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-items: center;
			flex: 0 0 auto;
			width: calc(100vw / 8);

			@include media-breakpoint-down(lg) {
				width: calc(100vw / 4);
			}

			@include media-breakpoint-down(md) {
				width: calc(100vw / 1.7);
			}

			&:before,
			&:after{
				content: '';
				position: absolute;
				background-color: $blue-medium;
				width: 50%;
				height: 1px;
				top: 44px;
				left: 0;
				z-index: -1;
			}

			&:after{
				left: 50%;
			}
			&:first-child:before{
				opacity: 0;
			}

			&:last-child:after{
				opacity: 0;
			}

			.month{
				font-size: 16px;
				font-weight: $bold;
				color: $blue-medium;
				margin-bottom: 6px;
			}

			.ico{
				margin-bottom: 14px;
			}

			.iconify{
				font-size: 27px;
				color: $green-base;
				background-color: $white;
				border-radius: 27px;
			}

			.text{
				font-size: 16px;
				font-weight: 400;
				color: $blue-medium;
				text-align: center;
				/*margin-bottom: 6px;*/

				ul{
					@extend %default-list-clean-style;
					text-align: left;
					
					
					li{
						position: relative;
						padding-left: 20px;
						&:before{
							content: '✓';
							position: absolute;
							left: 0;
							/*width: 20px;
							*height: 20px;*/
							font-size: 15px;
							/*display: flex;
							align-items: center;
							justify-content: center;*/
							font-weight: bold;
							color: $blue-medium;
							margin-right: 5px;
						}

						&+li{
							margin-top: 10px;
						}
					}
				}
				p{}
			}		
		}

		&-sl-wrap{
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-items: center;
			flex: 0 0 auto;
			padding-right: 20px;
			padding-left: 20px;
		}

		&-sl-navs{
			margin-top: 47px;
			display: flex;
			justify-content: center;
			position: relative;
			z-index: 1;

			@include media-breakpoint-down(lg) {
				display: none;
			}

			button{
				font-size: 35px;
				color: $green-base;
				position: relative;
				outline: none;
				display: inline-block;
				border: none;
				text-decoration: none;
				background: transparent;
				cursor: pointer;
				-webkit-appearance: none;
				-moz-appearance: none;
				line-height: 1;
				opacity: .5;
				padding: 0;
				margin: 0 6px;
				-webkit-transition: all .2s ease-in;
				-moz-transition: all .2s ease-in;
				-ms-transition: all .2s ease-in;
				-o-transition: all .2s ease-in;
				transition: all .2s ease-in;

				&:hover,
				&:focus{
					text-decoration: none;
					outline: none;
					opacity: 1;
					background: transparent;
				}

				&:active{}
			}	
		}	
	}

	/*Content text*/
	&-content{
		&-text{
			padding-top: 123px;
			padding-bottom: 123px;
			position: relative;

			@include media-breakpoint-down(md) {
				padding-top: 10vh;
				padding-bottom: 10vh;
			}

			&:before{
				content: '';
				position: absolute;
				top: -30%;
				right: 0;
				width: 100%;
				height: 130%;
				background-image: url('../images/big-leaf.png');
				background-position-x: 83%;
				background-position-y: top;
				background-repeat: no-repeat;

				@include media-breakpoint-down(lg) {
					top: -100px;
					left: 0;
					width: 100%;
					height: calc(100% + 100px);
				}
			}

			p{
				font-size: 14px;
				font-weight: $regular;
				color: #000;
				line-height: 2.42;
			}		
		}
	}	

}

/*Roadmap*/
.count-7{
	@include media-breakpoint-up(lg) {
		visibility: hidden;
	}
}

.scroll-container{
	@include media-breakpoint-up(lg) {
		&+.scroll-container{ margin-top: 60px; }
	}
	@include media-breakpoint-down(md) {
		&+.scroll-container{ margin-top: 40px; }
	}
}

/*Up lg*/
.count{
	&-0, &-1, &-2, &-3, &-4, &-5, &-6{
		@include media-breakpoint-up(lg) {
			visibility: hidden;
		}
	}
}

.nav-hide{
	@include media-breakpoint-up(lg) {
		visibility: hidden;
		height: 0;
		margin: 0;
	}
}
