.error-page {
  padding: 10rem 0;
  position: relative;
  text-align: center;
  &:before {
    color: #F3F6F9;
    content: attr(data-error);
    font-size: 40vw;
    font-weight: bold;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: -1;

     @include media-breakpoint-up(lg) {
      font-size: 20rem;
     }
  }
}
