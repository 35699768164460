body {
  background-color: $white-bg;
  font-family: $base-font-family;
  font-size: 16px;
  @include font-rem(16);
  color: $black;
  position: relative;
  
}

main{ overflow-y: hidden; }
.hide{display: none;}
.fixed{ position: fixed; }

.txtcenter{ text-align: center; }

.img-responsive{
	@extend %image-responsive;
}

.horizontal-list-clean-style {
   @extend %default-list-clean-style;
   @extend %flex-dir-row;
}

.-flex-row{
	@extend %flex-dir-row;
	align-items: center;

}
.-hfull{
  min-height: 100vh;
}

.-space-between{
	justify-content: space-between;
}

blockquote{
  max-width: 70%;
  font:{
    size: 18px;
    weight: $medium;
  };
  @include font-rem(18);
  line-height: 1.5;   
  color: $blue-dark;
  display: flex;
  justify-content: center;
  margin: 40px auto 13px;
  text-align: left;


  
  p{
    border-left: 4px solid $green-base;
    padding-left: 47px;
    line-height: inherit;
    margin-bottom: 0;
  }

  &+p,
  &+h3,
  &+h4,
  &+h5,
  &+h6{ margin-top: 56px; }
}

.grayscale{
  @include grayscale();
  &:hover{
   /* @include grayscale-on-mouseover();*/
  }
}