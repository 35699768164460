@charset "UTF-8";


// Helpers
@import 'helpers/variables';
/*@import 'helpers/responsive';*/
@import 'helpers/mixins';
/*@import 'helpers/wp';*/

// Base
@import 'base/bootstrap/bootstrap-reboot';
@import 'base/bootstrap/bootstrap-grid';
/*@import 'base/bootstrap/forms';
@import 'base/bootstrap/bootstrap-utilities';*/
@import 'base/structure';
@import 'base/titles';
@import 'base/main';

// Components
@import 'components/header';
@import 'components/dropdown-menu';
//@import 'components/header-pages';
@import 'components/footer';
@import 'components/buttons';
@import 'components/subscribe';
@import 'components/forms';
@import 'components/socials-list';
@import 'components/custom-checkbox';
@import 'components/404';

/*@import 'components/highlight';*/
@import 'components/menu-offcanvas';
@import 'components/gdpr';


// Vendors
@import 'vendors/tiny-slider';

// Blog
/*@import 'blog/index';
@import 'blog/articles';
@import 'blog/latest';
@import 'blog/single';
@import 'blog/slider';*/
//@import 'blog/page-name';

// Pages
@import 'pages/homepage';
@import 'pages/about';
@import 'pages/vacancy';
@import 'pages/contact';
/*@import 'pages/blog-single';
@import 'pages/404';*/

// Fixing for custom media queries
/*@import 'fixing/custom-media-queries';*/
